<script>
import UsersPage from "@/views/admin/pages/UsersPage.vue";
import CompilationsPage from "@/views/admin/pages/CompilationsPage.vue";

export default {
  name: "AdminPage",
  components: {
    UsersPage, CompilationsPage
  },
  data() {
    return {
      currentTab: 'UsersPage',
      tabs: [
        {
          name: 'Пользователи',
          path: 'UsersPage'
        },
        // {
        //   name: 'Подборки',
        //   path: 'CompilationsPage',
        // }
        ],
      drawer: true
    }
  },
  computed: {
    isAdmin() {
      return this.$store.state.auth.isAdmin
    },
    currentTabComponent() {
      return this.currentTab
    }
  },
  methods: {
    changeComp(tab) {
      this.currentTab = tab
    }
  },
  created() {
    this.$store.dispatch('getUser')
        .then(() => {
          if (!this.isAdmin) {
            this.$router.push({name: 'home'})
          }
        })
  },
}
</script>

<template>
  <v-navigation-drawer
      v-model="drawer"
      location="left"
  >
    <v-list>
      <v-list-item v-for="tab in tabs" :key="tab" @click="changeComp(tab.path)" :active="tab.path === currentTab">
        {{ tab.name }}
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
  <v-container>
    <component :is="currentTabComponent"/>
  </v-container>
</template>

<style scoped>

</style>
import CompilationApi from "@/api/compilation";

const state = {
    list: null,
    films: null,
    isLoading: false
};

const mutations = {
    createCompilationS(state) {
        state.isLoading = true
    },
    createCompilationF(state) {
        state.isLoading = false
    },

    getCompilationS(state) {
        state.isLoading = true
        // state.films = null
    },
    getCompilationF(state, payload) {
        state.isLoading = false
        state.films = payload
    },

    getListCompilationS(state) {
        state.isLoading = true
        state.list = null
    },
    getListCompilationF(state, payload) {
        state.isLoading = false
        state.list = payload
    },

    editCompilationS(state) {
        state.isLoading = true
    },
    editCompilationF(state) {
        state.isLoading = false
    },

    removeCompilationS(state) {
        state.isLoading = true
    },
    removeCompilationF(state) {
        state.isLoading = false
    },
};

const actions = {
    createCompilation({commit}, form) {
        commit('createCompilationS')
        return CompilationApi
            .create(form)
            .then(({data}) => commit('createCompilationF', data))
    },
    getListCompilation({commit}) {
        commit('getListCompilationS')
        return CompilationApi
            .all()
            .then(({data}) => commit('getListCompilationF', data.data))
    },
    getCompilation({commit}, {id}) {
        commit('getCompilationS')
        return CompilationApi
            .detail(id)
            .then(({data}) => commit('getCompilationF', data.data.films))
    },
    removeCompilation({commit}, {id}) {
        commit('removeCompilationS')
        return CompilationApi
            .remove(id)
            .then(() => commit('removeCompilationF'))
    },
    updateCompilation({commit}, {data, type}) {
        commit('editCompilationS')
        return type === 'add'
            ? CompilationApi
                .addFilm(data)
                .then(() => commit('editCompilationF'))
            : CompilationApi
                .removeFilm(data)
                .then(() => commit('editCompilationF'))
    }
};

export default {
    state,
    mutations,
    actions,
};
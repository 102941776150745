<script>
import axios from "@/api/axios";
import UserStatus from "@/components/widgets/UserStatus.vue";

export default {
  name: "UsersPage",
  components: {UserStatus},
  data() {
    return {
      data: null,
      page: 1,
      editMode: false
    }
  },
  methods: {
    async getData() {
      await axios.get(`v1/admin/users?page=${this.page}`).then(({data}) => this.data = data)
    },
    async changePremium(id) {
      await axios.put(`v1/admin/user/${id}/change-premium`)
          .then(() => this.getData())
          .catch(() => console.log('error'))
    }
  },
  mounted() {
    this.getData()
  },
  watch: {
    page() {
      this.getData()
    }
  }
}
</script>

<template>

  <div class="text-h4 mb-5">Пользователи</div>
  <v-container>
    <v-row v-if="data" class="mb-5">
      <v-col cols="12" sm="4" v-for="user in data.users" :key="user">
        <v-card rounded="lg">
          <v-card-title>
            <div class="d-flex justify-space-between">
              <span>{{ user.username }}</span>
              <v-btn v-if="user.role !== 1"
                  :color="user.role === 2 ? 'purple' : 'grey'" variant="tonal" @click="changePremium(user.id)">
                Premium
              </v-btn>
            </div>
          </v-card-title>
          <v-card-subtitle>
            {{ user.email }}
          </v-card-subtitle>
          <v-card-item>
            <UserStatus :status="user.role"/>
          </v-card-item>
        </v-card>
      </v-col>
    </v-row>
    <v-pagination :length="data.pagination.totalPage" v-model="page" v-if="data"></v-pagination>
  </v-container>
</template>

<style scoped>

</style>
<template>
  <v-container>
    <!--  Баннер  -->
    <v-img class="slider rounded-xl"
           cover
           width="100%"
           height="150">
      <div class="d-flex flex-column fill-height justify-start align-center text-white main">
        <h1 class="text-h3 font-weight-thin mb-4">My Movie</h1>
        <h4 class="subheading text-uppercase">
          лучшие фильмы всех континентов!
        </h4>
      </div>
    </v-img>
    <!--  Навигация  -->
    <template v-if="compilations && compilations.length">
      <v-slide-group
          :show-arrows="!mobile"
          mandatory
      >
        <v-slide-group-item
            v-for="item in compilations" :key="item.id"
        >
          <v-chip
              :prepend-icon="item?.image"
              class="mr-2"
              :class="{'text-white bg-black': item?.id === currentTab, 'text-grey': item?.id !== currentTab}"
              @click="getCompilation(item?.id)">
            {{ item?.title }}
            <template #append v-if="isAdmin">
              <!--  Удаление compilation  -->
              <CompilationRemoveForm @submit="removeCompilation" :id="item?.id"/>
            </template>
          </v-chip>
        </v-slide-group-item>
      </v-slide-group>
    </template>

    <!--  Создание compilation  -->
    <div class="mt-4">
    <CompilationCreateForm
        v-if="isAdmin"
        class-component="mr-2"
        @submit="afterCreateCompilation"/>
    <!--  Добавление film  -->
    <CompilationAddFilms
        v-if="isAdmin && compilations && compilations.length"
        @submit="addItemCompilation"/>
    <!--  Список films  -->
    </div>
    <film-list class="my-5" :films="films" v-if="films"
               @remove="removeItemCompilation"
               @add-fav="addFav"
               @remove-fav="removeFav"
               @add-to-watch="addToWatch"
               @remove-from-watch="removeFromWatch"
    />
  </v-container>
</template>

<script>
import CompilationCreateForm from "@/components/forms/CompilationCreateForm.vue";
import CompilationRemoveForm from "@/components/forms/CompilationRemoveForm.vue";
import CompilationAddFilms from "@/components/forms/CompilationAddFilms.vue";
import FilmList from "@/components/FilmList.vue";

import { useDisplay } from 'vuetify'

export default {
  name: "TestPage",
  components: {
    FilmList,
    CompilationAddFilms, CompilationRemoveForm, CompilationCreateForm
  },
  setup() {

    const { mobile } = useDisplay()

    return { mobile }
  },
  data: () => {
    return {
      currentTab: 1,
      error: '',
      name: '',
      select: '',
      search: '',
      items: []
    }
  },
  computed: {
    isAdmin() {
      return this.$store.state.auth.isAdmin
    },
    isLoading() {
      return this.$store.state.film.isLoading ?? this.$store.state.film.isSearching
    },
    compilations() {
      return this.$store.state.compilation.list
    },
    films() {
      return this.$store.state.compilation.films
    }
  },
  methods: {
    async addToWatch(id) {
      await this.$store.dispatch('addToWatchLater', {filmId: id}).then(() => this.getCompilation(this.currentTab))
    },
    async removeFromWatch(id) {
      await this.$store.dispatch('removeToWatchLater', {filmId: id}).then(() => this.getCompilation(this.currentTab))
    },
    async addFav(id) {
      await this.$store.dispatch('setFavorite', {filmId: id})
          .then(() => this.getCompilation(this.currentTab))
    },
    async removeFav(id) {
      await this.$store.dispatch('removeFavorite', {filmId: id})
          .then(() => this.getCompilation(this.currentTab))
    },
    afterCreateCompilation() {
      this.getList()
          .then(() => {
            this.currentTab = this.compilations.at(-1).id
            this.getCompilation(this.compilations.at(-1).id)
          });
    },
    async addItemCompilation(id) {
      const data = {
        compilation_id: this.currentTab,
        film_id: id
      }
      await this.$store.dispatch('updateCompilation', {data, type: 'add'})
          .then(() => this.getCompilation(this.currentTab))
    },
    async removeItemCompilation(id) {
      const data = {
        compilation_id: this.currentTab,
        film_id: id
      }
      await this.$store.dispatch('updateCompilation', {data, type: 'remove'})
          .then(() => this.getCompilation(this.currentTab))
    },
    async removeCompilation(id) {
      await this.$store.dispatch('removeCompilation', {id})
          .then(() => {
            this.getList().then(() => {
              if (this.compilations.length) {
                this.currentTab = this.compilations[0].id
                this.getCompilation(this.compilations[0].id)
              }
            })
          })
    },
    async getList() {
      await this.$store.dispatch('getListCompilation')
    },
    async getCompilation(id) {
      this.$store.dispatch('startProgressBar')
      this.currentTab = id
      await this.$store.dispatch('getCompilation', {id})
          .then(() => this.$store.dispatch('stopProgressBar'))
    }
  },
  created() {
    this.$store.dispatch('getUser')
  },
  mounted() {
    this.$store.dispatch('startProgressBar')
    this.getList()
        .then(() => {
          this.currentTab = this.compilations[0].id
          this.getCompilation(this.currentTab)
        })
        .catch(() => {})
    this.$store.dispatch('stopProgressBar')
  }
}
</script>

<style scoped>
.slider {
  box-shadow: 0 5px 5px #a419ff;
  top: -40px;
}

.main {
  margin-top: 3%;
}

@media screen and (max-width: 600px) {
  .main {
    margin-top: 10%;
  }
}
</style>
import axios from "@/api/axios";

const addToWatch = filmId => {
    return axios.post('v1/watch-later', {
        'kinopoisk_id': filmId
    })
}

const removeFromWatch = filmId => {
    return axios.delete(`v1/watch-later/${filmId}`)
}

const getList = () => {
    return axios.get('v1/watch-later')
}

export default {
    addToWatch,
    removeFromWatch,
    getList
}
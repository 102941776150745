<template>
  <v-card-subtitle>Введите код из письма</v-card-subtitle>
  <v-card-item>
    <form @submit.prevent>
      <v-text-field
          density="comfortable"
          :model-value="email"
          readonly
          variant="solo"
          disabled
      />
      <v-text-field
          name="code"
          hint="Код не пришел? Проверьте спам"
          variant="outlined"
          density="comfortable"
          autofocus
          label="Код"
          type="text"
          class="mt-2 mb-2 w-75"
          v-model="code"
          :error-messages="codeError"
          autocomplete="off"
          clearable
      />
      <v-text-field
          variant="outlined"
          label="Новый пароль"
          density="comfortable"
          type="password"
          class="mt-2"
          v-model="pass"
          :error-messages="v$.pass.$errors.map((e) => e.$message)"
          autocomplete="off"
          clearable
      />
      <v-text-field
          variant="outlined"
          label="Повторите"
          density="comfortable"
          class="mt-2"
          v-model="passConfirm"
          :error-messages="v$.passConfirm.$errors.map((e) => e.$message)"
          autocomplete="off"
      />
    </form>
  </v-card-item>
  <v-card-actions class="mx-4 mb-4">
    <v-btn @click="submit" variant="tonal" block>Отправить</v-btn>
  </v-card-actions>
</template>

<script>
import {useVuelidate} from "@vuelidate/core";
import {helpers, maxLength, minLength, numeric, required, sameAs} from "@vuelidate/validators";
import axios from "@/api/axios";

export default {
  setup() {
    return {v$: useVuelidate()};
  },
  emits: ['submit'],
  name: "CheckCode",
  props: {
    email: {
      type: String,
      required: true,
      default: 'example@mail.com'
    },
    password: {
      type: String,
      required: false,
      default: '0000'
    }
  },
  data() {
    return {
      code: null,
      error: null,
      pass: null,
      passConfirm: null,
    }
  },
  computed: {
    codeError() {
      return this.error ?? this.v$.code.$errors.map((e) => e.$message)
    }
  },
  validations() {
    return {
      code: {
        required: helpers.withMessage("Обязательное поле", required),
        minLength: helpers.withMessage("Введите 6-и значный пароль", minLength(6)),
        maxLength: helpers.withMessage("Введите 6-и значный пароль", maxLength(6)),
        numeric: helpers.withMessage("Код состоит из цифр", numeric),
      },
      pass: {
        required: helpers.withMessage("Обязательное поле", required),
        minLength: helpers.withMessage("Не менее 8 сиволов", minLength(8))
      },
      passConfirm: {
        required: helpers.withMessage("Обязательное поле", required),
        sameAs: helpers.withMessage("Пароли не совпадают", sameAs(this.pass))
      }
    }
  },
  methods: {
    async submit() {
      const isFormCorrect = await this.v$.$validate();
      if (isFormCorrect) {
        await axios.post('v1/password/change', {
          code: this.code,
          email: this.email,
          password: this.pass,
          password_confirmation: this.passConfirm
        }).then(() => {
          this.$emit('submit', {
            page: 'code',
            data: {
              code: this.code,
              password: this.passConfirm
            }
          })
        }).catch(() => this.error = 'Код неверный, повторите попытку')
      }
    }
  }
}
</script>

<style scoped>

</style>
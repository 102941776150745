<template>
  <v-chip v-if="chips" label variant="outlined">{{ formatted }}</v-chip>
  <span v-else>{{ formatted }}</span>

</template>

<script>
export default {
  name: "AgeLimits",
  props: {
    ageLimits: {
      type: String,
      required: false,
      default: '0'
    },
    chips: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    formatted() {
      return +/\d+/.exec(this.ageLimits) + '+';
    }
  }
};
</script>

<style scoped></style>
<template>
  <v-chip class="mr-2"
          color="info"
          label
          @click="dialog = !dialog">
    <v-icon>mdi-magnify</v-icon>
    <template class="d-none d-sm-inline ml-1">Поиск</template>
  </v-chip>
  <dialog-modal :show="dialog" @close="dialog = false">
    <template #title>
      Поиск
    </template>
    <template #subtitle>
      Найдется все!
    </template>
    <SearchForm @submit="submit" @clear="clear"/>
    <v-row v-if="data" class="mt-4 mb-1">
      <v-col cols="3" class="my-auto">
        <v-img :src="data.posterUrlPreview"/>
      </v-col>
      <v-col>
        <v-card>
          <span>{{ data.nameRu ?? data.nameEn }}</span>
          <div class="my-2">
            <RatingStar :rating="Number(data.rating)"/>
            <span class="text-caption">{{ data.year }}</span>
            <span class="ml-2 text-caption text-grey">({{ data.type === "FILM" ? "фильм" : "сериал" }})</span>
          </div>
          <v-banner :text="data.description" lines="three" stacked class="pt-1 pl-0"></v-banner>
        </v-card>
      </v-col>
    </v-row>
    <template #actions>
      <div class="d-flex flex-column align-center w-100">
        <v-btn :disabled="!data" color="success" block variant="tonal" @click="getPage('detail')">Подробнее</v-btn>
        <v-btn class="mt-4 mx-0" color="info" block variant="tonal" @click="getPage('search')">Расширенный поиск</v-btn>
      </div>
    </template>
  </dialog-modal>
</template>

<script>
import SearchForm from "@/components/forms/SearchForm.vue";
import DialogModal from "@/components/UI/DialogModal.vue";
import RatingStar from "@/components/UI/RatingStar.vue";

export default {
  name: "HeaderSearch",
  components: {RatingStar, DialogModal, SearchForm},
  data() {
    return {
      dialog: false,
      data: null
    };
  },
  methods: {
    clear() {
      this.data = null;
    },
    submit(film) {
      this.data = film
    },
    getPage(route) {
      this.dialog = false

      route === 'detail'
          ? this.$router.push({
            name: "detailFilmPage",
            params: {filmId: this.data.filmId},
          })
          : this.$router.push({
            name: "advanced-search"
          })
    }
  }
};
</script>

<style>
.v-dialog {
  margin-top: -174px;
}
</style>

<template>
  <v-btn
      variant="text"
      icon="mdi-close-box"
      color="primary"
      density="compact"
  ></v-btn>
</template>

<script>
export default {
  name: "CloseBtn"
}
</script>

<style scoped>

</style>
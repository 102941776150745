import favoriteApi from "@/api/favorite";

const state = {
    data: null,
    error: null,
    isLoading: false,
    changing: false,
};

const mutations = {
    getFavoriteStart(state) {
        // state.data = null;
        state.error = null;
        state.isLoading = true;
    },
    getFavoriteSuccess(state, payload) {
        state.isLoading = false;
        state.data = payload;
    },
    getFavoriteFailure(state, payload) {
        state.isLoading = false;
        state.error = payload;
    },

    setFavoriteStart(state) {
        state.error = null;
        state.changing = true;
    },
    setFavoriteSuccess(state) {
        state.changing = false;
    },
    setFavoriteFailure(state, payload) {
        state.changing = false;
        state.error = payload;
    },

    removeFavoriteStart(state) {
        state.error = null;
        state.changing = true;
    },
    removeFavoriteSuccess(state) {
        state.changing = false;
    },
    removeFavoriteFailure(state, payload) {
        state.changing = false;
        state.error = payload;
    },
};

const actions = {
    getFavorite(context, {page}) {
        context.commit("getFavoriteStart");
        return favoriteApi
            .getFavorite(page)
            .then((response) => {
                context.commit("getFavoriteSuccess", response.data);
            })
            .catch((response) => {
                    context.commit("getFavoriteFailure", response.message)
                    return response.message;
                }
            );
    },
    setFavorite(context, {filmId}) {
        context.commit("setFavoriteStart");
        return favoriteApi
            .setFavorite(filmId)
            .then(() => context.commit("setFavoriteSuccess"))
            .catch((response) =>
                context.commit("setFavoriteFailure", response.message)
            );
    },
    removeFavorite(context, {filmId}) {
        context.commit("removeFavoriteStart");
        return favoriteApi
            .removeFavorite(filmId)
            .then(() => context.commit("removeFavoriteSuccess"))
            .catch((response) =>
                context.commit("removeFavoriteFailure", response.message)
            );
    },
};

export default {
    state,
    mutations,
    actions
};

<template>
  <v-icon icon="mdi-close-circle" color="red-darken-4" @click.stop="dialog = true"></v-icon>

  <DialogModal :max-width="400" :show="dialog" @close="dialog = false">
    <template v-slot:title>Вы уверены?</template>
    <template #subtitle>Рубрика будет удалена навсегда</template>
    <template #actions>
      <div class="w-50 mr-2">
        <v-btn @click="submit" color="red" variant="tonal" block>Да</v-btn>
      </div>
      <div class="w-50">
        <v-btn @click="dialog = false" variant="tonal" block>Отмена</v-btn>
      </div>
    </template>
  </DialogModal>
</template>

<script>
import DialogModal from "@/components/UI/DialogModal.vue";

export default {
  emits: ['submit'],
  name: "CompilationRemoveForm",
  components: {DialogModal},
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      dialog: false
    }
  },
  methods: {
    submit() {
      this.dialog = false
      this.$emit('submit', this.id)
    }
  }
}
</script>

<style scoped>

</style>
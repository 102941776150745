<template>
  <v-list min-width="230px" density="compact" rounded="lg">
    <v-list-item prepend-icon="mdi-arrow-collapse-right" @click="dialogLogin = true">
      Вход
    </v-list-item>
    <v-list-item prepend-icon="mdi-account-plus"
                 @click="dialogRegister = true">
      Регистрация
    </v-list-item>
  </v-list>

  <v-dialog v-model="dialogRegister" theme="light" persistent>
    <v-card theme="dark" max-width="600" class="mx-auto rounded-lg" width="100%">
      <v-card-title class="d-flex justify-space-between align-center">
        Создать аккаунт
        <close-btn @click="closeRegisterDialog"></close-btn>
      </v-card-title>
      <v-card-item class="mb-5">
        <register-form @register="register"></register-form>
      </v-card-item>
    </v-card>
  </v-dialog>

  <v-dialog v-model="dialogLogin" theme="light" persistent>
    <v-card theme="dark" max-width="600" class="mx-auto rounded-lg" width="100%">
      <v-card-title class="d-flex justify-space-between align-center">
        Войти на сайт
        <close-btn @click="closeLoginDialog"></close-btn>
      </v-card-title>
      <v-card-item class="mb-5">
        <login-form @login="login"></login-form>
      </v-card-item>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapState} from "vuex";
import WhatsNew from '@/components/WhatsNew';
import CloseBtn from "@/components/UI/CloseBtn";
import LoginForm from "@/components/LoginForm";
import RegisterForm from "@/components/RegisterForm";

export default {
  name: "DotsMenu",
  components: {
    WhatsNew,
    CloseBtn,
    LoginForm,
    RegisterForm
  },
  data() {
    return {
      email: "",
      password: {
        password: "",
        confirm: "",
      },
      dialogRegister: false,
      dialogLogin: false,
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      isLoading: (state) => state.auth.loading,
      isLogged: (state) => state.auth.isLogged,
    }),
    accountColor() {
      return this.isLogged ? 'success' : 'grey'
    },
    accountIcon() {
      return this.isLogged ? 'mdi-account-check' : 'mdi-account'
    },
    username() {
      const s = this.user?.username
      if (s && s.length > 30) {
        return s.substring(0, 30) + '...'
      }
      return s
    }
  },
  methods: {
    clearErrors() {
      this.$store.commit('clearErrors');
    },
    closeRegisterDialog() {
      this.dialogRegister = !this.dialogRegister;
      this.clearErrors();
    },
    closeLoginDialog() {
      this.dialogLogin = !this.dialogLogin;
      this.clearErrors();
    },
    register(credentials) {
      this.$store.dispatch("registerUser", credentials).then(() => {
        this.$store.dispatch('login', credentials).then(() => {
          this.$store.dispatch('getUser');
          this.dialogRegister = false;
        });
      })
    },
    login(credentials) {
      this.$store.dispatch('login', credentials).then(() => {
        this.$store.dispatch('getUser').then(() => {
          this.dialogLogin = false;
        });
      });
    },
    logout() {
      this.$store.dispatch("logout");
    },
  }
}
</script>

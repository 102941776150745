<script>
export default {
  name: "CompilationsPage"
}
</script>

<template>
  <div>Compilations</div>
</template>

<style scoped>

</style>
import axios from "axios";

axios.defaults.baseURL = process.env.VUE_APP_URL_API;
axios.interceptors.request.use(config => {
    const token = window.localStorage.getItem('accessToken');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
})

// axios.defaults.headers['X-API-KEY'] = '06e410d4-a714-4411-8511-4d5b14eec303'; // PROD
// FOR DEV //
// axios.defaults.headers['X-API-KEY'] = process.env.VUE_APP_KINOPOISK_API_TOKEN;
// axios.defaults.headers['X-API-KEY'] = '1dd6909e-18ad-47e3-b880-c15755642ccb';
// axios.defaults.headers['X-API-KEY'] = '34bed0a7-28a9-4434-a0b0-4190a25716a0';
// axios.defaults.headers['X-API-KEY'] = '4b7f454a-9794-49e6-a83a-d6754397cd37';

export default axios;
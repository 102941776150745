import {createRouter, createWebHistory} from "vue-router/dist/vue-router";
import NotFoundPage from "@/views/NotFoundPage";
import HomePage from "@/views/HomePage";
import DetailFilmPage from "@/views/film/detail/DetailFilmPage.vue";
import ErrorPage from "@/views/ErrorPage";
import ComingSoonPage from "@/views/ComingSoonPage";
import FavoritePage from "@/views/film/FavoritePage";
import WatchLater from "@/views/film/WatchLater.vue";
import AdvancedSearch from "@/views/AdvancedSearch.vue";
import TestPage from "@/views/TestPage.vue";
import AdminPage from "@/views/admin/AdminPage.vue";
import UsersPage from "@/views/admin/pages/UsersPage.vue";

const router = createRouter({
    history: createWebHistory(),
    linkActiveClass: 'text-white',
    routes: [
        {
            path: '/',
            name: 'home',
            meta: {
                breadcrumb: {
                    label: 'Application',
                    href: '/'
                }
            },
            component: HomePage
        },


        {
            path: '/admin',
            name: 'adminPage',
            component: AdminPage
        },
        {
            path: '/admin/users',
            name: 'admin.users',
            component: UsersPage
        },

        {
            path: '/film/:filmId',
            name: 'detailFilmPage',
            component: DetailFilmPage
        },
        {
            path: '/films/favorite',
            name: 'favoritePage',
            component: FavoritePage
        },
        {
            path: '/films/watch-later',
            name: 'watchLater',
            component: WatchLater
        },
        {
            path: '/404',
            name: '404',
            component: NotFoundPage,
            meta: {
                breadcrumb: {
                    label: 'Page Not Found'
                }
            },
        },
        {
            path: '/500',
            name: '500',
            component: ErrorPage,
            meta: {
                breadcrumb: {
                    label: 'Server internal error'
                }
            },
        },
        {
            path: '/400',
            name: 'ComingSoon',
            component: ComingSoonPage,
            meta: {
                breadcrumb: {
                    label: 'Coming soon..'
                }
            },
        },
        {
            path: '/search',
            name: 'advanced-search',
            component: AdvancedSearch
        },
        {
            path: '/:catchAll(.*)',
            redirect: '/404'
        },
        {
            path: '/test',
            component: TestPage
        }
    ]
});

export default router;

<template>
  <form>
    <v-text-field
      required
      class="mt-2"
      variant="outlined"
      v-model="email"
      :error-messages="v$.email.$errors.map(e => e.$message)"
      :counter="30"
      label="Ваше e-mail"
      clearable
    ></v-text-field>
    <div class="d-flex">
      <v-text-field
        required
        type="password"
        class="mt-2"
        variant="outlined"
        v-model="password"
        :error-messages="errors"
        :counter="30"
        label="Ваше пароль"
        clearable
        @keydown.enter="submitForm"
      ></v-text-field>
    </div>
    <v-btn class="mr-2" color="primary" @click="submitForm" variant="tonal" :loading="false">
      Войти
    </v-btn>
    <ResetForm />
  </form>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required, email, helpers } from "@vuelidate/validators";
import { mapState } from "vuex";
import router from "@/router";
import ResetForm from "@/components/reset_form/ResetForm.vue";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: "loginForm",
  emits: ["login"],
  components: {
    ResetForm
  },
  data() {
    return {
      email: "",
      password: "",
    };
  },
  computed: {
    ...mapState({
      error: (state) => state.auth.loginError,
    }),
    errors() {
      if (this.error) {
        return 'Неправильный логин или пароль'
      }
      return this.v$.password.$errors.map((e) => e.$message)
    }
  },
  validations() {
    return {
      email: {
        required: helpers.withMessage("Обязательное поле", required),
        email: helpers.withMessage("Введен некорректный email", email),
      },
      password: {
        required: helpers.withMessage("Обязательное поле", required),
      },
    };
  },

  methods: {
    router() {
      return router
    },
    async submitForm() {
      const isFormCorrect = await this.v$.$validate();
      if (isFormCorrect) {
        this.$emit("login", { email: this.email, password: this.password });
      }
    },
  },
};
</script>

<style scoped></style>
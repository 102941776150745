<template>
  <v-row>
    <TransitionGroup name="list">
      <v-col
          v-for="film in films"
          :key="film.kinopoiskId ?? film.filmId"
          cols="12"
          lg="2"
          md="3"
          sm="4"
      >
        <film-card :film="film"
                   @remove="$emit('remove', film.kinopoiskId)"
                   @add-fav="$emit('add-fav', film.kinopoiskId)"
                   @remove-fav="$emit('remove-fav', film.kinopoiskId)"
                   @add-to-watch="$emit('add-to-watch', film.kinopoiskId)"
                   @remove-from-watch="$emit('remove-from-watch', film.kinopoiskId)"
        ></film-card>
      </v-col>
    </TransitionGroup>
  </v-row>
</template>

<script>
import FilmCard from "@/views/film/FilmCard.vue";

export default {
  emits: ['remove', 'add-fav', 'remove-fav', 'add-to-watch', 'remove-from-watch'],
  name: "FilmList",
  components: {
    FilmCard
  },
  props: {
    films: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
.primary {
  color: #c944ff;
}

.search {
  color: #1c1c1c;
}

.card-top {
  min-height: 55px;
  padding-left: 5px;
  padding-bottom: 5px;
  background: rgb(2, 0, 36);
  background: linear-gradient(
      180deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(0, 0, 0, 1) 25%,
      rgba(255, 255, 255, 0) 100%
  );
}

.v-info {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
}

.card-top--title {
  font-size: 0.7rem;
  text-align: left;
  padding: 3px;
}

.v-card-actions {
  max-height: 40px;
}

@media screen and (max-width: 600px) {

  .card-top {
    min-height: 120px;
    color: #c4c4c4;
  }

  .card-top--title {
    font-size: 0.9rem;
  }

  .rating {
    min-height: 30px;
  }

  .card-bottom {
    background-color: rgb(28, 28, 28);
    min-height: 50px;
  }

  .text-subtitle-2,
  .card-bottom,
  .rating {
    font-size: 1.1rem !important;
  }

  .v-col-12 {
    max-width: 50%;
  }
}
</style>
import axios from "@/api/axios";

const setFavorite = filmId => {
    return axios.post('v1/favorite', {
        kinopoiskId: filmId
    }, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

const getFavorite = page => {
    return axios.get(`v1/favorite?page=${page}`)
        .then(response => response.data);
}

const removeFavorite = filmId => {
    return axios.delete('v1/favorite', {
        headers: {
            'Content-Type': 'application/json'
        },
        data: {
            kinopoiskId: filmId
        }
    })
}

export default {
    getFavorite,
    setFavorite,
    removeFavorite
}

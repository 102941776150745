<template>
  <v-card
    max-height="400"
    class="overflow-x-hidden"
    variant="text"
  >
    <v-list>
      <p class="mb-2 text-subtitle-1 font-weight-medium">
        В главных ролях
        <v-icon icon="mdi-chevron-right" size="sm"></v-icon>
      </p>
      <ul>
        <li v-for="person in staffList" :key="person">
          <a
              class="text-caption"
              target="_blank"
              :href="`https://kinopoisk.ru/name/${person.staffId}`"
          >{{ person.nameRu }}</a
          >
        </li>
        <li>
          <a class="mb-2 text-subtitle-2 font-weight-medium"
             :href="`https://kinopoisk.ru/film/${filmId}/cast`"
             target="_blank"
          >все актеры ...</a
          >
        </li>
      </ul>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: "TheStaff",
  props: {
    filmId: {
      type: String,
      required: true,
    },
    staffList: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
a:hover {
  color: #6200ee;
}
</style>

import axios from "@/api/axios";

const searchByKeywords = (search, page) => {
    return axios.post(`v1/search-by-keywords`, {
        search: search,
        page: page,
    }, {
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(response => response.data);
}

const searchByFilter = (filter) => {
    return axios.post('v1/search-by-filter', filter).then(({data}) => data)
}

const getCountries = () => {
    return axios.get('v1/countries').then(({data}) => data)
}

const getGenres = () => {
    return axios.get('v1/genres').then(({data}) => data)
}

const autocomplete = (search) => {
    return axios.post(`v1/search-by-keywords`, {
        search: search,
        page: 1,
    }, {
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(response => response.data);
}

const getByID = filmId => {
    return axios.get(`v1/film/${filmId}`).then(response => response.data);
}

const getLastEpisodes = (name, year) => {
    return axios.post(`v1/last-episodes`, {name, year}, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export default {
    searchByKeywords,
    searchByFilter,
    getByID,
    getLastEpisodes,
    autocomplete,
    getCountries,
    getGenres
}
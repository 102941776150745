<script>
export default {
  name: "UserStatus",
  props: {
    status: {
      type: Number,
      required: true
    }
  },
  computed: {
    content() {
      let res = ''
      switch (this.status) {
        case 0:
          res = {
            color: 'success',
            text: 'active'
          }
          break
        case 1:
          res = {
            color: 'red',
            text: 'admin'
          }
          break
        case 2:
          res = {
            color: 'purple',
            text: 'PREMIUM'
          }
      }
      return res
    }
  }
}
</script>

<template>
  <v-chip density="compact" :color="content.color">{{ content.text }}</v-chip>
</template>

<style scoped>

</style>
<template>
  <v-dialog :max-width="maxWidth" v-model="dialog">
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <div class="text-h6">
          <slot name="title"></slot>
        </div>
        <close-btn @click="$emit('close')"/>
      </v-card-title>
      <v-card-subtitle>
        <slot name="subtitle"></slot>
      </v-card-subtitle>
      <v-card-item>
        <slot></slot>
      </v-card-item>
      <v-card-actions class="ma-4 mt-0 d-flex justify-space-between">
        <slot name="actions"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CloseBtn from "@/components/UI/CloseBtn.vue";

export default {
  emits: ['close'],
  name: "DialogModal",
  props: {
    maxWidth: {
      type: Number,
      required: false,
      default: 500
    },
    show: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    dialog() {
      return this.show
    }
  },
  components: {CloseBtn}
}
</script>

<style scoped>

</style>
<template>
  <v-btn variant="text" @click="modal = true">Забыли пароль?</v-btn>
  <v-dialog v-model="modal" max-width="500">
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <div class="text-h6">Сброс пароля</div>
        <close-btn @click="modal = false"/>
      </v-card-title>
      <keep-alive>
        <component
            :is="currentTab"
            :email="form.email"
            :password="form.password"
            @submit="changeTab"
        />
      </keep-alive>
    </v-card>
  </v-dialog>
</template>

<script>
import CloseBtn from "@/components/UI/CloseBtn.vue";
import SendCode from "@/components/reset_form/SendCode.vue";
import CheckCode from "@/components/reset_form/CheckCode.vue";
import SuccessPage from "@/components/reset_form/SuccessPage.vue";

export default {
  name: "ResetForm",
  components: {
    CloseBtn,
    SendCode, CheckCode, SuccessPage
  },
  data() {
    return {
      form: {
        email: 'example@mail.com',
        code: '0000',
        password: '0000'
      },
      modal: false,
      currentTab: 'SendCode',
      tabs: ['SendCode', 'CheckCode', 'SuccessPage']
    }
  },
  methods: {
    changeTab(e) {
      if (e.page === 'email') {
        this.form.email = e.data
        this.currentTab = 'CheckCode'
      }
      if (e.page === 'code') {
        this.form.password = e.data.password
        this.currentTab = 'SuccessPage'
      }
      if (e.page === 'success') {
        this.modal = false
        setTimeout(() => {
          this.currentTab = 'SendCode'
          this.form = {
            email: 'example@mail.com',
            code: '0000',
            password: '0000'
          }
        }, 200)
      }
    }
  }
}

</script>

<style scoped>

</style>
<script>
export default {
  name: "ExternalSources",
  props: {
    sources: {
      type: Array,
      required: true
    }
  }
}
</script>

<template>
  <v-row v-if="sources.length">
    <v-col cols="6" sm="3" v-for="source in sources" :key="source" >
      <a :href="source.url" target="_blank" class="d-inline-block text-center">
        <v-img :src="source.logoUrl" width="100" class="rounded-xl"/>
        {{ source.platform }}
      </a>
    </v-col>
  </v-row>
  <template v-else>
    Нет данных
  </template>
  <!--  <div>-->
  <!--    [ { "url": "https://okko.tv/movie/pirates-of-the-caribbean-the-curse-of-the-black-pearl?utm_medium=referral&utm_source=yandex_search&utm_campaign=new_search_feed", "platform": "Okko", "logoUrl": "https://avatars.mds.yandex.net/get-ott/239697/7713e586-17d1-42d1-ac62-53e9ef1e70c3/orig" }, { "url": "https://www.ivi.ru/watch/58273?utm_source=yandex&utm_medium=wizard", "platform": "Иви", "logoUrl": "https://avatars.mds.yandex.net/get-ott/2419418/0dfd1724-848f-4725-9160-abc571f41c11/orig" }, { "url": "https://24h.tv/contents/2003-pirates-of-the-caribbean-t-546319865573540828", "platform": "24ТВ", "logoUrl": "https://avatars.mds.yandex.net/get-ott/2439731/85e83b8d-1159-4781-bad5-ce0a809b3843/orig" }, { "url": "https://wink.ru/media_items/54934640?utm_source=yandex&utm_medium=koldunschick&utm_content=name", "platform": "Wink", "logoUrl": "https://avatars.mds.yandex.net/get-ott/1672343/54096cbe-cc3b-41c9-8e44-990ebbca8d61/orig" } ]-->
  <!--  </div>-->
</template>

<style scoped>

</style>
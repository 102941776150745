<template>
  <span>{{ time }}</span>
</template>

<script>
export default {
  name: "MinToTime",
  props: {
    length: {
      type: Number,
      required: true,
    },
  },
  computed: {
    time() {
      const ch = Math.floor(this.length / 60);
      let min = this.length - ch * 60;
      min = min < 10 ? `0${min}` : min;
      return `${ch}:${min}`;
    },
  },
};
</script>

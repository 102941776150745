<template>
  <v-container>
    <div class="d-flex">
      <v-chip label class="mr-3 mb-3" :to="{name: 'favoritePage'}" prepend-icon="mdi-bookmark">
        Мои фильмы
      </v-chip>
      <v-chip label :to="{name: 'watchLater'}" prepend-icon="mdi-clock" color="grey">
        Буду смотреть
      </v-chip>
    </div>
    <v-divider class="mb-5"></v-divider>
    <v-expansion-panels variant="popout" v-if="films && films.films.length">
      <v-expansion-panel
          title="Фильтры"
      >
        <v-expansion-panel-text class="pt-3">
          <the-filter
              :films="films.films"
              @set-filter="setFilter"
              :filterData="films.filterData"
          ></the-filter>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
      <div class="d-flex flex-column" v-if="filtered && films && films.films.length > 0">
        <film-list class="my-5" :films="filtered" v-if="filtered"
                   @remove-fav="removeFavorite"
                   @add-fav="addFavorite"
                   @remove-from-watch="removeFromWatch"
                   @add-to-watch="addToWatch"
        >
        </film-list>
        <v-pagination :length="films.pagination.totalPage" v-model="page"></v-pagination>
      </div>
    <div class="mt-4" v-if="films && films.films.length === 0">
      Не найдено
    </div>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import TheFilter from "@/layout/TheFilter";
import FilmList from "@/components/FilmList";

export default {
  name: "SearchResultPage",
  components: {
    TheFilter,
    FilmList
  },
  data() {
    return {
      message: "",
      filtered: null,
      page: 1,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      isLogged: (state) => state.auth.isLogged,
      films: (state) => state.favorite.data,
      isLoading: (state) => state.favorite.isLoading,
      error: (state) => state.favorite.error,
    }),
  },
  methods: {
    async addToWatch(id) {
      await this.$store.dispatch('addToWatchLater', {filmId: id}).then(() => this.getFavorite())
    },
    async removeFromWatch(id) {
      await this.$store.dispatch('removeToWatchLater', {filmId: id}).then(() => this.getFavorite())
    },
    async removeFavorite(id) {
      await this.$store.dispatch('removeFavorite',{filmId: id})
          .then(() => this.getFavorite())
    },
    async addFavorite(id) {
      await this.$store.dispatch('setFavorite', {filmId: id}).then(() => this.getFavorite())
    },
    getUser() {
      this.$store
        .dispatch("getUser")
        .then(() => {
          this.getFavorite();
        })
        .catch(() => this.$router.push({ name: "home" }));
    },
    getFavorite() {
      this.$store.dispatch("getFavorite", { page: this.page }).then(() => {
        if (! this.error) {
          this.setFilter();
        }
      });
    },
    setFilter(films) {
      if (films) {
        this.filtered = films;
      } else {
        this.filtered = this.films.films
      }
      this.$store.dispatch('stopProgressBar');
    },
  },
  mounted() {
    this.$store.dispatch('startProgressBar')
    if (!this.user) {
      this.getUser();
    } else {
      this.getFavorite();
    }
  },
  watch: {
    page() {
      this.getFavorite(this.page)
    },
  }
};
</script>

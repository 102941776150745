<template>
  <v-autocomplete
      class="mt-4 mb-2"
      density="comfortable"
      v-model="select"
      v-model:search="search"
      @update:search="debouncedSubmit"
      @click:clear="$emit('clear')"
      item-title="nameRu"
      item-value="nameRu"
      :loading="isLoading"
      :items="items"
      prepend-inner-icon="mdi-magnify"
      hint="Например: Дедпул, Зеленая миля ..."
      label="Фильмы, сериалы, мультики ..."
      variant="outlined"
      autofocus
      clearable
      hide-no-data
  >
    <template v-slot:item="{ props, item }">
      <div class="item ml-2 mb-4 d-flex align-center hover" v-bind="props" @click="selectItem(item?.raw)">
        <div class="item_logo">
          <v-avatar :image="item.raw.posterUrlPreview" rounded="lg"/>
        </div>
        <div class="item_info d-flex flex-column ml-3">
          <div class="item_info--title text-short max-w-250">{{ item.raw.nameRu ?? item.raw.nameEn }}</div>
          <div class="item_info--subtitle text-caption text-grey">
            {{ item.raw.type === 'TV_SERIES' ? 'сериал' : 'фильм' }}
            <span class="text-orange">{{ item.raw.year }}</span>
          </div>
        </div>
        <div class="ml-auto">
          <rating-star :rating="item.raw.rating*1" />
        </div>
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
import debounce from "lodash.debounce";
import RatingStar from "@/components/UI/RatingStar.vue";

export default {
  name: "SearchForm",
  components: {RatingStar},
  emits: ['submit','clear'],
  data: () => {
    return {
      select: '',
      search: '',
      items: []
    }
  },
  computed: {
    isLoading() {
      return this.$store.state.film.isSearching ?? false
    },
  },
  methods: {
    debouncedSubmit: debounce(function () {
      if (this.search.length >= 3 && this.search) {
        this.onSubmit()
      }
    }, 500),
    async onSubmit() {
      this.$store.dispatch('autocomplete', {search: this.search}).then(() => {
        this.items = this.$store.state.film.searchItems.films;
      })
    },
    selectItem(film) {
      this.$emit('submit', film)
      this.search = ''
    }
  }
}
</script>

<style scoped>
.hover:hover {
  cursor: pointer;
  background-color: #1c1c1c;
}
</style>
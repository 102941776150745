<template>
  <v-row>
    <v-col cols="12" sm="3">
      <v-tabs
          v-model="tab"
          slider-color="purple"
          direction="vertical"
      >
        <v-tab
            :value="item.index"
            v-for="item in items" :key="item.index">
          <v-chip variant="text" :prepend-icon="item.icon" :color="item.index === tab ? 'purple' : ''">
            {{ item.index === 1 && user && user.role > 0 ? 'Online' : item.title }}
          </v-chip>
        </v-tab>
      </v-tabs>
    </v-col>
    <v-col cols="12" sm="9">
      <v-window v-model="tab">
        <v-window-item :value="1">
          <template v-if="user && user.role > 0">
            <iframe class="rounded-lg"
                    :src="`${players[0].url}&noads=1`"
                    width="100%" height="450" frameborder="0" scrolling="no" allowfullscreen/>
          </template>
          <template v-else>
            <iframe class="rounded-lg"
                    :src="players[0].url"
                    width="100%" height="450" frameborder="0" scrolling="no" allowfullscreen/>
          </template>
        </v-window-item>
        <v-window-item :value="2">
          <iframe class="rounded-lg"
                  :src="players[1].url"
                  width="100%" height="450" frameborder="0" scrolling="no" allowfullscreen/>
        </v-window-item>
        <v-window-item :value="3">
          <div class="my-5">
            <ExternalSources :sources="sources"/>
          </div>
        </v-window-item>
<!--        <v-window-item :value="4">-->
<!--          <div class="my-5">-->
<!--            <iframe is="x-frame-bypass" src="http://widgets.kinopoisk.ru/discovery/trailer/216?onlyPlayer=1&autoplay=1&cover=1" width="500" height="300"></iframe>-->
<!--          </div>-->
<!--        </v-window-item>-->
      </v-window>
    </v-col>
  </v-row>
</template>

<script>
import ExternalSources from "@/views/film/components/ExternalSources.vue";

export default {
  components: {ExternalSources},
  emits: ['status'],
  name: "ThePlayer",
  data() {
    return {
      noAds: 0,
      tab: 1,
      items: [
        {
          index: 1,
          icon: 'mdi-television-play',
          title: 'Online'
        },
        // {
        //   index: 2,
        //   icon: 'mdi-television-play',
        //   title: 'Плеер 2'
        // },
        {
          index: 3,
          icon: 'mdi-currency-usd',
          title: 'По подписке'
        },
        // {
        //   index: 4,
        //   icon: 'mdi-movie-open-play',
        //   title: 'Трейлеры'
        // }
      ],
      players: [
        // {
        //   name: 'Плеер 1',
        //   url: `https://v1693382249.bazon.site/kp/${this.filmId}?trans=1,2,3,4,5,6&poster=`
        // },
        // {
        //   name: 'Плеер 2',
        //   url: `//arcchid.link/sow/${this.filmId}`
        // },
        {
          name: 'Плеер 2',
          url: `https://a-bomba.as.newplayjj.com:9443/?kp=${this.filmId}&amp;token=92c0f8792c3c2b429222e842f694f3`
        }
      // ;token=2ec54dd3cf7d65a168294b9fd90cf1
      //   fork-as.newplayjj.com:9443/?token_movie=340454ade170d65387f216475d8238&amp;token=92c0f8792c3c2b429222e842f694f3
      ]
    }
  },
  props: {
    filmId: {
      type: String,
      required: true
    },
    user: {
      type: Object,
      required: false,
      default: null
    }
  },
  computed: {
    sources() {
      return this.$store.state.film.data.externalSources ?? []
    }
  },
  methods: {
    //   setScript() {
    //     return new Promise((resolve => {
    //       let script = document.createElement("script");
    //       script.setAttribute("src", "https://yohoho.cc/yo.js");
    //       script.setAttribute("id", "yoyo");
    //       document.head.appendChild(script);
    //       resolve();
    //     }))
    //   },
    //   async checkPlayer() {
    //     await setTimeout(() => {
    //       let s = document.getElementById('yohoho-iframe');
    //       let w = document.getElementById('yohoho');
    //       if (s && s.src) {
    //         const mediaQuery = window.matchMedia('(max-width: 640px)')
    //         if (mediaQuery.matches) {
    //           w.style.height = s.style.height = '305px';
    //         }
    //         w.style.width = s.style.width = '100%';
    //       }
    //       if (s && !s.src) {
    //         w.innerText = 'Если нет видео, попробуйте обновить страницу или войти позже';
    //         w.style = null;
    //         const p = document.querySelector("video[id='yohoho']");
    //         if (p) {
    //           p.remove();
    //         }
    //       }
    //     }, 6000);
    //   },
    // },
    // created() {
    //   this.setScript().then(() => {
    //     this.checkPlayer();
    //   });
  }
}
</script>

<style scoped>

</style>
<template>
  <v-app-bar elevation="24" class="d-flex" theme="dark">
    <v-progress-linear
        :active="statusBar"
        :indeterminate="true"
        absolute
        bottom
        height="2"
        color="deep-purple-accent-4"
    ></v-progress-linear>
    <v-container>
      <v-row class="align-center" no-gutters>
        <v-col cols="5" class="d-flex align-center">
          <v-btn icon="mdi-menu" @click="drawer = !drawer"/>
          <v-btn
              href="/"
              variant="plain"
              prepend-icon="mdi-movie-roll"
              class="ml-3"
              color="primary"
          >My Movie
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="7" class="d-flex justify-end align-center">
          <search-form></search-form>
          <!--          <dots-menu></dots-menu>-->
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
  <v-navigation-drawer v-model="drawer" class="my-drawer" temporary>
    <template v-if="user">
      <v-list class="ml-2">
        <v-list-item
            v-if="user"
            :title="user.username"
            :subtitle="user.email"
        >
          <span class="text-caption text-grey-darken-2">
          Регистрация: {{ new Date(user.created_at).toLocaleDateString() }}</span>
        </v-list-item>
      </v-list>
      <div class="ml-5 mt-2 mb-4">
        <UserStatus :status="user.role" />
      </div>
      <v-divider></v-divider>
      <v-list
          :lines="false"
          nav
      >
        <v-list-item :to="{name: 'home'}" prepend-icon="mdi-home">
          Главная
        </v-list-item>
        <v-list-item :to="{name: 'favoritePage'}" prepend-icon="mdi-bookmark">
          Мои фильмы
        </v-list-item>
        <v-list-item :to="{name: 'watchLater'}" prepend-icon="mdi-clock">
          Буду смотреть
        </v-list-item>
        <v-list-item :to="{name: 'adminPage'}" prepend-icon="mdi-cogs" v-if="user.role === 1">
          Панель управления
        </v-list-item>
      </v-list>
    </template>
    <template v-else>
      <dots-menu></dots-menu>
    </template>
    <template v-slot:append>
      <div class="pa-2">
        <v-list-item class="text-left mt-4">
          <whats-new></whats-new>
        </v-list-item>
        <v-btn v-if="user"
               prepend-icon="mdi-exit-run"
               @click="logout"
               variant="tonal"
               class="bg-red-accent-4"
               block
        >
          Выйти
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import DotsMenu from "@/components/DotsMenu";
import SearchForm from "@/components/HeaderSearch";
import WhatsNew from "@/components/WhatsNew.vue";
import UserStatus from "@/components/widgets/UserStatus.vue";

export default {
  name: "TheHeader",
  components: {UserStatus, WhatsNew, DotsMenu, SearchForm},
  data() {
    return {
      updates: false,
      drawer: false
    }
  },
  computed: {
    statusBar() {
      return this.$store.state.progressBar.loading ?? false
    },
    user() {
      return this.$store.state.auth.user
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
  }
};
</script>

<style>
.my-drawer .v-navigation-drawer__content {
  display: flex;
  flex-direction: column;
}
</style>

<template>
  <v-card-subtitle>Укажите свою почту и следуйте инстуркциям в письме</v-card-subtitle>
  <v-card-item>
    <form @submit.prevent>
      <v-text-field
          :loading="isLoading"
          variant="outlined"
          autofocus
          label="Введите свой email"
          hint="example@mail.com"
          density="comfortable"
          class="mt-2 mb-2"
          v-model="userMail"
          :error-messages="errors"
      />
    </form>
  </v-card-item>
  <v-card-actions class="mx-4 mb-4">
    <v-btn @click="submit" variant="tonal" block class="bg-purple-darken-4 mb-2">Получить</v-btn>
  </v-card-actions>
</template>

<script>
import {useVuelidate} from "@vuelidate/core";
import {email, helpers, required} from "@vuelidate/validators";
import axios from "@/api/axios";

export default {
  setup() {
    return {v$: useVuelidate()};
  },
  emits: ['submit'],
  name: "SendCode",
  data() {
    return {
      isLoading: false,
      userMail: null,
      error: null
    }
  },
  props: {
    email: {
      type: String,
      required: false,
      default: 'example@mail.com'
    },
    password: {
      type: String,
      required: false,
      default: '0000'
    }
  },
  computed: {
    errors() {
      return this.error ?? this.v$.userMail.$errors.map((e) => e.$message)
    }
  },
  validations() {
    return {
      userMail: {
        required: helpers.withMessage("Обязательное поле", required),
        email: helpers.withMessage("Введен некорректный email", email)
      }
    }
  },
  methods: {
    async submit() {
      const isFormCorrect = await this.v$.$validate();
      if (isFormCorrect) {
        this.error = null;
        this.isLoading = true;
        await axios.post('v1/password/reset', {
          email: this.userMail
        }).then(() => {
          this.isLoading = false;
          this.$emit('submit', {
            page: 'email',
            data: this.userMail
          })
        }).catch(() => {
          this.error = 'Пользователя с таким e-mail нет в системе'
          this.isLoading = false;
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
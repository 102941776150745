<template>
  <v-container class="d-flex flex-column justify-center h-100">
    <v-card class="d-flex flex-column align-center" variant="flat">
      <v-card-title>
      <span class="text-h1">500</span>
      </v-card-title>
      <v-card-text>
        Something bad happened...
      </v-card-text>
      <v-card-actions>
        <v-btn :to="{name: 'home'}">
          back to home
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "NotFoundPage"
}
</script>

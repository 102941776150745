import axios from "@/api/axios";

const register = (credentials) => {
    return axios.post('v1/register', credentials);
}

const login = (credentials) => {
    return axios.post('v1/login', credentials);
}

const logout = () => {
    return axios.post('v1/logout')
}

const getUser = () => {
    return axios.post('v1/me')
}

export default {
    register,
    login,
    logout,
    getUser
}
<template>
  <v-container>
    <div class="d-flex flex-column">
      <p class="text-caption text-medium-emphasis mb-2 text-justify">
        Настоящим уведомляем, что на внутренних ресурсах сайта не хранится
        контент, содержащий объекты авторских прав и нарушающий законодательство
        в области авторского права. Администрация сайта также не осуществляет
        деятельность, связанную с публикацией нелицензионного контента,
        незаконно украденного и находящегося под защитой правообладателей. Вся
        видеоинформация, размещенная на сайте, представляет собой исключительно
        ссылки на видеоматериалы, находящиеся в свободном доступе для просмотра
        в сети Интернет на сторонних ресурсах. Сбор информации о доступных в
        сети Интернет видеоматериалов, и размещение на них ссылок на страницах
        сайта осуществляется в автоматическом режиме. Администрация сайта в
        данном случае не осуществляет контроль над добавляемыми на сайт
        ссылками.
      </p>
      <div class="d-flex justify-center copyright">
        <p class="p-0 m-0">
          {{ new Date().getFullYear() }}&nbsp;—&nbsp;<strong>My Movie</strong>
        </p>
        <a href="mailto:admin@my-movie.ru" class="ml-2 link-info text-lowercase">Контакты</a>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "TheFooter",
};
</script>

<style scoped>
.v-dialog {
  margin-top: -150px !important;
}

.link-info {
  color: #960cbd;
  transition: 1s;
}
.link-info:hover {
  color: #606060;
  transition: 1s;
}
.text-caption {
  font-size: 0.6rem !important;
}

@media screen and (max-width: 600px) {
  .copyright {
    font-size: x-small;
  }
}
</style>

<template>
  <v-chip density="comfortable" label :prepend-icon="icon" variant="text" :color="color">
    {{ ratingValue }}</v-chip>
</template>

<script>

export default {
  name: "RatingStar",
  props: {
    rating: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data: () => {
    return {
      colors: [
        "#212121",
        "#ff0000",
        "#ff0000",
        "#ff0000",
        "#ff0000",
        "#777777",
        "#777777",
        "#3bb33b",
        "#d39d0a",
        "#d39d0a",
        "#d39d0a",
      ],
    }
  },
  computed: {
    ratingValue() {
      return isNaN(this.rating) ? '' : this.rating
    },
    color() {
      if (isNaN(this.rating)) {
        return 'grey-darken-2'
      }
      return this.colors[Math.trunc(this.rating)]
    },
    icon() {
      if (!this.rating) {
        return 'mdi-star-off'
      }
      return this.rating > 8 ? 'mdi-trophy-award' : 'mdi-star';
    }
  }
}
</script>

<style scoped>

</style>
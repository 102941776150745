import axios from "@/api/axios";

const create = (data) => {
    return axios.post('v1/compilation/create', data)
}
const detail = (id) => {
    return axios.get(`v1/compilation/${id}`)
}

const all = () => {
    return axios.get('v1/compilation')
}

const remove = (id) => {
    return axios.delete( `v1/compilation/${id}`)
}
const addFilm = (data) => {
    return axios.post( 'v1/compilation/add', data)
}

const removeFilm = (data) => {
    return axios.post( 'v1/compilation/delete', data)
}


export default {
    create,
    all,
    detail,
    remove,
    addFilm,
    removeFilm
}

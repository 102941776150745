<template>
  <v-chip
      :class="classComponent"
      @click="dialog = true"
      color="success"
  >
    Добавить фильм
    <template #prepend>
      <v-icon size="small" class="mr-2">mdi-movie-plus</v-icon>
    </template>
  </v-chip>

  <DialogModal :show="dialog" @close="dialog = false">
    <template #title>Добавить фильм</template>
    <template #subtitle>Поиск по всем фильмам планеты</template>
    <SearchForm @submit="getData"></SearchForm>
    <v-row v-if="data" class="mt-4 mb-1">
      <v-col cols="3" class="my-auto">
        <v-img :src="data.posterUrlPreview"/>
      </v-col>
      <v-col>
        <v-card>
           <span>{{ data.nameRu ?? data.nameEn }}</span>
          <div class="my-2">
            <RatingStar :rating="Number(data.rating)"/>
            <span class="text-caption">{{ data.year }}</span>
            <span class="ml-2 text-caption text-grey">({{ data.type === "FILM" ? "фильм" : "сериал" }})</span>
          </div>
          <v-banner :text="data.description" lines="three" stacked class="pt-1 pl-0"></v-banner>
        </v-card>
      </v-col>
    </v-row>
    <template #actions>
      <v-btn :disabled="!data" color="success" block variant="tonal" @click="submit">Добавить</v-btn>
    </template>
  </DialogModal>
</template>

<script>
import DialogModal from "@/components/UI/DialogModal.vue";
import SearchForm from "@/components/forms/SearchForm.vue";
import RatingStar from "@/components/UI/RatingStar.vue";

export default {
  emits: ['submit'],
  name: "CompilationAddFilms",
  components: {RatingStar, SearchForm, DialogModal},
  data() {
    return {
      dialog: false,
      data: null
    }
  },
  props: {
    classComponent: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {
    getData(film) {
      this.data = film
    },
    submit() {
      this.$emit('submit', this.data.filmId)
      this.dialog = false
      this.data = null
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <p class="text-subtitle-1 mt-2">О фильме</p>
  <v-table class="text-caption" density="comfortable">
    <tbody>
    <tr>
      <td>Год</td>
      <td>{{ filmData.year }}</td>
    </tr>
    <tr>
      <td>Страна</td>
      <td>
        {{ countries }}
      </td>
    </tr>
    <tr>
      <td>Жанр</td>
      <td>
        <v-chip
            label
            color="primary"
            v-for="genre in genres"
            :key="genre"
            variant="tonal"
            density="compact"
            class="ma-1"
        >
          {{ genre }}
        </v-chip>
      </td>
    </tr>
    <template v-if="!prodactionStatus">
      <tr>
        <td>Время</td>
        <td>
          <min-to-time
              :length="filmData.filmLength"
              v-if="filmData.filmLength"
          ></min-to-time>
        </td>
      </tr>
    </template>
    <tr>
      <td>Возраст</td>
      <td>
        <age-limits :ageLimits="filmData.ageLimits" chips></age-limits>
      </td>
    </tr>
    </tbody>
  </v-table>
</template>

<script>
import MinToTime from "@/components/heplers/MinToTime";
import AgeLimits from "@/components/heplers/AgeLimits";

export default {
  name: "FilmInfo",
  components: {
    MinToTime,
    AgeLimits,
  },
  props: {
    filmData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    countries() {
      return this.filmData.countries;
    },
    genres() {
      return this.filmData.genres.split(',')
    },
    prodactionStatus() {
      return this.filmData.productionStatus;
    },
  },
};
</script>

<style scoped></style>

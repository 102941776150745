import getFilmsApi from "@/api/film";

const state = {
    searchItems: [],
    data: null,
    film: null,
    isLoading: false,
    isSearching: false,
    error: null,
};

const mutations = {
    autoSearchS(state) {
        state.isSearching = true;
        // state.searchItems = [];
    },
    autoSearchF(state, payload) {
        state.isSearching = false;
        state.searchItems = payload;
    },

    searchStart(state) {
        state.error = null;
        // state.data = null;
        state.isSearching = true;
    },
    searchSuccess(state, payload) {
        state.isSearching = false;
        state.data = payload;
    },
    searchFailure(state, payload) {
        state.isSearching = false;
        state.error = payload;
    },

    getByIdStart(state) {
        state.error = null;
        state.film = null;
        state.data = null;
        state.isLoading = true;
    },
    getByIdSuccess(state, payload) {
        state.isLoading = false;
        state.data = payload;
    },
    getByIdFailure(state, payload) {
        state.isLoading = false;
        state.error = payload;
    },

    // getLastEpisodesSuccess(state, payload) {
    //     state.film.episodes = payload.data;
    // }
};

const actions = {
    searchByKeywords(context, {search, page}) {
        context.commit("searchStart");
        return getFilmsApi
            .searchByKeywords(search, page)
            .then((response) => {
                context.commit("searchSuccess", response.data);
            })
            .catch((response) => {
                context.commit("searchFailure", response);
            });
    },

    autocomplete(context, {search}) {
        context.commit("autoSearchS");
        return getFilmsApi
            .autocomplete(search)
            .then((response) => {
                context.commit("autoSearchF", response.data);
            })
    },

    async getFilmByID(context, {filmId}) {
        context.commit("getByIdStart");
        return await getFilmsApi
            .getByID(filmId)
            .then((response) => {
                context.commit("getByIdSuccess", response.data);
            })
            .catch((response) => {
                context.commit("getByIdFailure", response);
            });
    },
    getLastEpisodes(context, {name, year}) {
        return new Promise(resolve => {
            getFilmsApi.getLastEpisodes(name, year)
                .then(response => {
                    return resolve(response.data);
                })
        })
    }

};

export default {
    state,
    mutations,
    actions
};

import watchLater from "@/api/watch-later";

const state = {
    data: null,
    isLoading: false
}
const mutations = {
    getWatchLaterS(state) {
        state.loading = true
    },
    getWatchLaterF(state, payload) {
        state.loading = false
        state.data = payload
    },
    addToWatchS(state) {
        state.loading = true
    },
    addToWatchF(state) {
        state.loading = false
    },
    removeFromWatchS(state) {
        state.loading = true
    },
    removeFromWatchF(state) {
        state.loading = false
    }
}
const actions = {
    getListToWatch({commit}) {
        commit('getWatchLaterS')
        return watchLater.getList().then(({ data }) => commit('getWatchLaterF', data))
    },
    addToWatchLater({commit}, {filmId}) {
        commit('addToWatchS')
        return watchLater.addToWatch(filmId).then(() => commit('addToWatchF'))
    },
    removeToWatchLater({commit}, {filmId}) {
        commit('removeFromWatchS')
        return watchLater.removeFromWatch(filmId).then(() => commit('removeFromWatchF'))
    },
}

export default {
    state,
    mutations,
    actions
}

<template>
  {{ short }}
</template>

<script>
export default {
  name: "ShortText",
  props: {
    text: {
      type: String,
      required: false,
      default: null
    },
    length: {
      type: Number,
      required: false,
      default: 70
    }
  },
  computed: {
    short() {
      if (this.text && this.text.length <= this.length) {
        return this.text.substring(0,this.length)
      }
      return this.text ? this.text.substring(0,this.length) + '...' : ''
    }
  }
}
</script>


<style scoped>

</style>
<template>
  <v-app theme="dark">
    <the-header></the-header>
    <v-main>
      <router-view :key="this.$route.fullPath" />
    </v-main>
    <v-footer theme="dark">
      <the-footer></the-footer>
    </v-footer>
  </v-app>
</template>

<script>
import TheHeader from "@/layout/TheHeader";
import TheFooter from "@/layout/TheFooter";

export default {
  name: "App",
  components: {
    TheFooter,
    TheHeader,
  },
  mounted() {
  },
};
</script>

<style>
.v-container {
  max-width: 1200px;
}
a {
  color: unset;
  text-decoration: none;
}

main {
  margin-bottom: 20px;
}

.max-w-250 {
  max-width: 250px;
}

.text-short {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  transition:  all 0.3s ease;
}

.list-enter-from {
  opacity: 0;
}
.list-leave-to {
  opacity: 0;
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
  position: absolute;
}

</style>
import authApi from '@/api/auth';

const state = {
    user: null,
    isLogged: false,
    isAdmin: false,
    data: null,
    loading: false,
    authError: null,
    registerError: null,
    loginError: null,
};
const mutations = {
    registerStart(state) {
        state.data = null;
        state.loading = true;
        state.registerError = null;
    },
    registerSuccess(state, payload) {
        state.data = payload;
        state.loading = false;
    },
    registerFailure(state, payload) {
        state.loading = false;
        state.registerError = payload;
    },

    loginStart(state) {
        state.data = null;
        state.loading = true;
        state.loginError = null;
    },
    loginSuccess(state) {
        state.loading = false;
    },
    loginFailure(state, payload) {
        state.loading = false;
        state.loginError = payload;
    },

    getUserStart(state) {
        state.user = null;
        state.loading = true;
        state.authError = null;
    },
    getUserSuccess(state, payload) {
        state.user = payload;
        state.isLogged = true;
        state.loading = false;
        state.isAdmin = payload.role === 1

    },
    getUserFailure(state, payload) {
        state.loading = false;
        state.authError = payload;
    },

    logoutStart(state) {
        state.error = null;
        state.isLoading = true;
    },
    logoutSuccess(state) {
        state.isLoading = false;
        state.isLogged = false;
        state.isAdmin = false
        state.user = null;
    },
    logoutFailure(state) {
        state.isLoading = false;
    },
    clearErrors(state) {
        state.loginError = state.authError = state.registerError = null;
    }
};
const actions = {
    registerUser(context, credentials) {
        context.commit('registerStart');
        return new Promise((resolve) => {
            authApi
                .register(credentials)
                .then(response => {
                    context.commit('registerSuccess', response.data)
                    resolve(response);
                })
                .catch(response => {
                    context.commit('registerFailure', response.message)
                })
        })
    },
    login(context, credentials) {
        context.commit('loginStart');
        return new Promise(resolve => {
            authApi
                .login(credentials)
                .then(response => {
                    localStorage.setItem('accessToken', response.data.access_token);
                    context.commit('loginSuccess');
                    resolve();
                    window.location.reload();
                })
                .catch(() => {
                    context.commit('loginFailure', 'Неверный логин или пароль')
                })
        })
    },
    getUser({ commit }) {
        commit('getUserStart');
        return authApi
            .getUser()
            .then(response => {
                commit('getUserSuccess', response.data)
            })
            .catch(response => {
                commit('getUserFailure', response)
                return response;
            })
    },
    logout(context) {
        context.commit('logoutStart');
        return new Promise(resolve => {
            authApi.logout()
                .then(() => {
                    localStorage.removeItem('accessToken');
                    context.commit('logoutSuccess');
                    resolve();
                    window.location.reload();
                })
                .catch(response => {
                    context.commit('logoutFailure', response)
                    return response;
                })
        })
    }
};

export default {
    state,
    mutations,
    actions
}
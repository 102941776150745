<template>
<!--  <v-snackbar-->
<!--      color="purple"-->
<!--      class="mt-10"-->
<!--      location="top"-->
<!--      v-model="snackbar"-->
<!--  >-->
<!--    Внимание! Сервис видеохостинга работает со сбоями. Видео может быть недоступно-->
<!--&lt;!&ndash;    <template v-slot:actions>&ndash;&gt;-->
<!--&lt;!&ndash;      <v-btn&ndash;&gt;-->
<!--&lt;!&ndash;          color="white"&ndash;&gt;-->
<!--&lt;!&ndash;          variant="text"&ndash;&gt;-->
<!--&lt;!&ndash;          @click="snackbar = false"&ndash;&gt;-->
<!--&lt;!&ndash;      >&ndash;&gt;-->
<!--&lt;!&ndash;        Закрыть&ndash;&gt;-->
<!--&lt;!&ndash;      </v-btn>&ndash;&gt;-->
<!--&lt;!&ndash;    </template>&ndash;&gt;-->
<!--  </v-snackbar>-->
  <v-container>
    <film-detail-skeleton v-if="isLoading"></film-detail-skeleton>
    <v-card v-if="filmData && filmId && film && !isLoading" rounded="lg">
      <v-card-subtitle class="d-flex justify-space-between align-center mt-4">
        <p>Обновлено {{ lastSync(film.lastSync) }}</p>
      </v-card-subtitle>
      <v-card-item>
        <v-row>
          <v-col cols="12" xl="3" lg="4" sm="6" xs="1" class="d-flex flex-column">
            <v-img
                :aspect-ratio="10 / 14"
                cover
                class="ma-3 rounded-lg elevation-8"
                :src="film.posterUrl"
                :lazy-src="film.posterUrlPreview"
            >
              <template v-slot:placeholder>
                <div class="d-flex align-center justify-center fill-height">
                  <v-progress-circular
                      color="grey-lighten-4"
                      indeterminate
                  ></v-progress-circular>
                </div>
              </template>
            </v-img>
          </v-col>
          <v-col cols="12" xl="6" lg="5" sm="6" xs="1">
            <p class="text-h4 d-flex align-baseline">
              {{ film.nameRu }} ({{ film.year }})
            </p>
            <p class="text-subtitle-1">
              {{ film.nameOriginal }}
              <age-limits :age-limits="film.ratingAgeLimits"></age-limits>
            </p>
            <p class="text-caption mb-3" v-if="film.slogan">
              {{ film.slogan }}
            </p>
            <v-divider class="mt-2"></v-divider>
            <div class="d-flex align-center">
              <rating-widget :rating="film.rating" v-if="film.rating"></rating-widget>
            </div>
            <div class="my-4" v-if="user">
              <v-btn
                  @click="addFav"
                  class="mr-2 mb-2 mb-sm-0"
                  :prepend-icon="buttonFav.icon"
                  variant="tonal"
                  :color="buttonFav.color"
                  size="small"
              >{{ buttonFav.text }}
              </v-btn>
              <v-btn
                  :color="buttonWatch.color"
                  @click="addToWatch"
                  class="mb-2 mb-sm-0" :prepend-icon="buttonWatch.icon" variant="tonal"
                  size="small">{{
                  buttonWatch.text
                }}
              </v-btn>
            </div>
            <film-info :filmData="film"></film-info>
          </v-col>
          <v-col cols="12" xl="3" lg="3" sm="12" xs="12">
            <v-expansion-panels class="d-lg-none d-xl-flex">
              <v-expansion-panel title="Актеры">
                <v-expansion-panel-text>
                  <the-staff :staffList="staff" :film-id="filmId"></the-staff>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
            <div class="d-none d-lg-flex d-xl-none flex-column">
              <the-staff :staffList="staff" :film-id="filmId"></the-staff>
            </div>
          </v-col>
          <v-col cols="12" xl="4" lg="4" v-if="film.type === 'TV_SERIES'">
            <template v-if="!episodes">
              Поиск новых серий
              <v-progress-linear indeterminate></v-progress-linear>
            </template>
            <last-episodes v-if="episodes && episodes.filmInfo" :film-episodes="episodes"></last-episodes>
            <v-chip v-if="episodes && !episodes.filmInfo" color="red">Нет информации о новых сериях</v-chip>
          </v-col>
          <v-col cols="12" xl="7" lg="7" class="pa-5">
            <v-card>
              <v-card-title class="mb-3">Сюжет</v-card-title>
              {{ film.description }}
            </v-card>
          </v-col>

        </v-row>
      </v-card-item>
      <v-divider class="ma-5"></v-divider>
      <v-card>
        <v-tabs
            class="mb-5"
            v-model="tab"
            centered
        >
          <v-tab class="text-green" value="tab-1" slider-color="green">
            <v-icon class="mr-2">mdi-movie</v-icon>
            <span>Смотреть</span>
          </v-tab>

          <v-tab class="text-deep-purple" slider-color="deep-purple" value="tab-2" v-if="images.total > 0">
            <v-icon class="mr-2">mdi-image</v-icon>
            Кадры из фильма
          </v-tab>
        </v-tabs>
        <v-window v-model="tab">
          <v-window-item :value="'tab-1'">
            <v-card>
              <v-card-item>
                <the-player :filmId="filmId" :user="user"></the-player>
              </v-card-item>
            </v-card>
          </v-window-item>
          <v-window-item
              :value="'tab-2'"
          >
            <v-card>
              <v-card-item>
                <film-images :images="images"></film-images>
              </v-card-item>
            </v-card>
          </v-window-item>
        </v-window>
      </v-card>
    </v-card>
  </v-container>
</template>

<script>
import {mapState} from "vuex";
import TheStaff from "@/views/film/components/TheStaff.vue";
import FilmInfo from "@/views/film/components/FilmInfo.vue";
import AgeLimits from "@/components/heplers/AgeLimits.vue";
import FilmImages from "@/views/film/components/FilmImages.vue";
import FilmDetailSkeleton from "@/components/UI/FilmDetailSkeleton.vue";
import ThePlayer from "@/views/film/detail/ThePlayer.vue";
import RatingWidget from "@/components/widgets/RatingWidgets.vue";
import LastEpisodes from "@/views/film/components/LastEpisodes";

export default {
  name: "DetailFilmPage",
  components: {
    LastEpisodes,
    FilmImages,
    TheStaff,
    FilmInfo,
    AgeLimits,
    FilmDetailSkeleton,
    ThePlayer,
    RatingWidget
  },
  data() {
    return {
      snackbar: true,
      tab: "",
      filmId: "",
      episodes: null,
      movie: ''
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      filmData: state => state.film.data,
      film: state => state.film.data.film,
      staff: state => state.film.data.staff,
      images: state => state.film.data.images,
      isLoading: state => state.film.isLoading
    }),
    buttonFav() {
      return this.movie.isFav
          ? {icon: 'mdi-bookmark', text: 'Сохранено', color: 'purple'}
          : {icon: 'mdi-bookmark-outline', text: 'Сохранить', color: 'grey'}
    },
    buttonWatch() {
      return this.movie.toWatch
          ? {icon: 'mdi-clock-check', text: 'Буду смотреть', color: 'info'}
          : {icon: 'mdi-clock-outline', text: 'Буду смотреть', color: 'grey'}
    },
  },
  methods: {
    async addToWatch() {
      if (this.film.toWatch) {
        this.movie.toWatch = false
        await this.$store.dispatch('removeToWatchLater', { filmId: this.filmId })
      } else {
        this.movie.toWatch = true
        await this.$store.dispatch('addToWatchLater', { filmId: this.filmId })
      }
    },
    async addFav() {
      if (this.film.isFav) {
        this.movie.isFav = false
        await this.$store.dispatch('removeFavorite', {filmId: this.filmId})
      } else {
        this.movie.isFav = true
        await this.$store.dispatch('setFavorite', {filmId: this.filmId})
      }
    },
    getFilmData() {
      this.$store.dispatch("getFilmByID", {filmId: this.filmId})
          .then(() => {
            this.movie = this.film
            this.$store.dispatch("getUser");
            if (this.film.type === 'TV_SERIES') {
              this.$store.dispatch('getLastEpisodes', {
                name: this.film.nameRu ?? this.film.nameEn,
                year: this.film.year
              }).then(response => this.episodes = response);
            }
          }).catch(() => {
        this.$router.push({
          name: "404"
        })
      })
    },
    lastSync(date) {
      return new Date(date).toLocaleDateString("ru-RU");
    },
    favChange(e) {
      this.film.isFav = e === 'added';
    }
  },
  created() {
    this.filmId = this.$route.params.filmId;
    this.getFilmData()
    // this.$store.dispatch("getUser");
  },
};
</script>
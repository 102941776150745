<template>
  <v-card theme="dark">
    <v-card-item>
      <v-sheet class="mr-10" width="340px" height="30px" :color="color">
        <v-progress-linear indeterminate striped color="#181818FF" height="30"></v-progress-linear>
      </v-sheet>
      <div class="mt-5 d-flex">
        <v-sheet class="mr-10  d-none d-sm-flex" width="340px" height="450px" :color="color">
          <v-progress-linear indeterminate striped color="#181818FF" height="450"></v-progress-linear>
        </v-sheet>
        <div class="d-flex flex-column mr-10">
          <v-sheet class="" width="400px" height="80px" :color="color">
            <v-progress-linear indeterminate striped color="#181818FF" height="80"></v-progress-linear>
          </v-sheet>
          <v-sheet class="mt-10" width="440px" height="300px" :color="color">
            <v-progress-linear indeterminate striped color="#181818FF" height="300"></v-progress-linear>
          </v-sheet>

        </div>
        <v-sheet class="" width="140px" height="310px" :color="color">
          <v-progress-linear indeterminate striped color="#181818FF" height="310"></v-progress-linear>
        </v-sheet>
      </div>
      <v-sheet class="mt-10" width="100%" height="150px" :color="color">
        <v-progress-linear indeterminate striped color="#181818FF" height="310"></v-progress-linear>
      </v-sheet>
    </v-card-item>
  </v-card>

</template>

<script>
export default {
  name: "CardSkeleton",
  computed: {
    color() {
      return '#4b4b4b';
    }
  }
}
</script>

const state = {
    loading: false
}

const mutations = {
    progressBarS(state) {
        state.loading = true
    },
    progressBarF(state) {
        state.loading = false
    }
}

const actions = {
    startProgressBar(context) {
        context.commit('progressBarS')
    },
    stopProgressBar(context) {
        setTimeout(() => context.commit('progressBarF'), 1000)
    },
}

export default {
    state,
    mutations,
    actions
}
<template>
  <v-chip
      :class="classComponent"
      @click="dialog = true"
      color="info"
  >
    Создать
    <template #prepend>
      <v-icon size="small" class="mr-2">mdi-plus-circle</v-icon>
    </template>
  </v-chip>
  <DialogModal :show="dialog" @close="dialog = false" :max-width="400">
    <template #title>Создать рубрику</template>
    <template #subtitle>Название должно быть уникальным</template>
    <template #default>
      <v-text-field
          class="mt-2"
          density="comfortable"
          v-model="name"
          label="Название"
          variant="outlined"
          :error-messages="error"
          autofocus
      />
    </template>
    <template #actions>
      <v-btn variant="tonal" block color="info" @click="create">Создать</v-btn>
    </template>
  </DialogModal>
</template>

<script>
import DialogModal from "@/components/UI/DialogModal.vue";

export default {
  emits: ['submit'],
  name: "CompilationCreateForm",
  components: {DialogModal},
  props: {
    classComponent: {
      type: String,
      required: false,
      default: ''
    }
  },
  data: () => {
    return {
      dialog: false,
      name: '',
      error: '',
    }
  },
  methods: {
    async create() {
      if (!this.name.trim()) {
        this.error = 'Обязательное поле'
        return
      }

      if (this.name.length < 3) {
        this.error = 'Минимум 3 символа'
        return
      }

      await this.$store.dispatch('createCompilation', {
        title: this.name
      }).then(() => {
        this.dialog = false
        this.error = ''
        this.name = ''
        this.$emit('submit')
      }).catch(({response}) => {
        if (response.status === 403) {
          this.error = 'Нет доступа'
        } else {
          this.error = 'Рубрика с таким названием уже существует'
        }
      })
    },
  }
}
</script>

<template>
  <v-sheet>
    <v-row>
      <v-col
        v-for="image in images.items"
        :key="image"
        class="d-flex child-flex"
        cols="12"
        lg="6"
        sm="6"
        xs="1"
      >
        <v-img
          :src="image.imageUrl"
          :lazy-src="image.previewUrl"
          class="lighten-2 rounded-lg"
          cover
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey-lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  name: "FilmImages",
  props: {
    images: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>

<template>
    <v-btn
        size="small"
      id="menu-activator"
      >
        версия v.4.0
    </v-btn>

    <v-menu activator="#menu-activator">
        <div class="overflow-auto mt-5 rounded-lg" style="max-height: 400px; max-width: 500px;">
            <v-card>
                <v-card-title>Список изменений</v-card-title>
            </v-card>
            <v-card v-for="update in updates" :key="update">
                <v-card-subtitle>Версия {{ update.version }}</v-card-subtitle>
                <v-card-item>
                    <ul>
                        <li class="mb-2" v-for="item in update.items" :key="item">
                            <v-icon color="green" size="small">mdi-check-bold</v-icon>&nbsp;{{ item }}
                        </li>
                    </ul>
                </v-card-item>
            </v-card>
        </div>
    </v-menu>
</template>

<script>
export default {
    name: "WhatsNew",
    data() {
        return {
            updates: [
              {
                version: '4.0',
                items: [
                  'Новые плееры',
                  'Чтобы смотреть без рекламы зарегистрируйтесь и отправьте текст "хочу premium" на почту admin@my-movie.ru'
                ]
              },
              {
                version: '3.5',
                items: [
                  'Добавлен расширенный поиск',
                  'Переработан блок со сборниками'
                ]
              },
                {
                version: '3.3',
                items: [
                  'Переработана карточка фильма',
                  'Добавлен раздел "Смотреть позже"',
                  'Добавлены сборники',
                  'Новый поиск',
                  'Устранен баг с поиском фильмов',
                  'Оптимизирована скорость подгрузки картинок'
                ]
              },
                {
                version: '3.2.3',
                items: [
                  'Добавлен функционал восстановления пароля'
                ]
              },
              {
                version: '3.2.2',
                items: [
                  'Новый авто-поиск',
                  'Устранены мелкие баги'
                ]
              },
                {
                    version: '3.2.1',
                    items: [
                        'Обновленная шапка',
                        'Обновленный feed',
                        'Ускорена работа приложения'
                    ]
                },
                {
                    version: '3.2',
                    items: [
                        'Улучшен список эпизодов для сериалов',
                        'Теперь вы не пропустите дату выхода эпизода своего любимого сериала',
                        'Повышена скорость и стабильность',
                    ]
                },
                {
                    version: '3.1',
                    items: [
                        'Добавлены эпизоды сериалов',
                        'Улучшена производительность',
                    ]
                },
                {
                    version: '3.0',
                    items: [
                        'Новый темный дизайн.',
                        'Новые сборники фильмов на главной',
                        'Устранены мелкие ошибки'
                    ]
                },
                {
                    version: '2.2.2',
                    items: [
                        'Добавлена кнопка "добавить в избранное" на странице фильма',
                        'Оптимизация. Устранены мелкие ошибки',
                        'Улучшенный поиск']
                }
            ]
        }
    },
    computed: {
        version() {
            return `v${process.env.VUE_APP_V}`;
        }
    },
}
</script>

<style scoped>
ul {
    list-style: square;
}

</style>
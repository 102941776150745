<template>
  <v-container>
  <v-col v-if="genres">
    <v-row class="flex-column flex-sm-row align-baseline">
      <v-col cols="12" sm="4">
        <v-slider
            prepend-icon="mdi-podium-silver"
            hide-details
            thumb-size="16"
            :track-color="ratingColor"
            step="1"
            show-ticks="always"
            :max="8"
            :color="ratingColor"
            v-model="rating"
            thumb-label="always"
            density="compact"
            @update:modelValue="debouncedRating"
        >
          <template v-slot:thumb-label="{ modelValue }">
            <div class="d-flex align-center">
                <span style="font-size: 1.2rem">{{ satisfactionEmojis[modelValue] }}</span>
                <span class="ml-1 font-weight-bold text-white">{{ modelValue }}</span>
            </div>
          </template>
        </v-slider>
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field
            clearable
            density="compact"
            variant="outlined"
            label="ключевое слово в названии"
            v-model="keyword"
            @update:modelValue="debouncedSearch"
            @click:clear="onSubmit"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4">
        <v-select
            density="compact"
            label="Год"
            v-model="yearRange"
            :items="years"
            item-title="title"
            item-value="range"
            @update:modelValue="onSubmit"
            clearable
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="3">
        <v-autocomplete
            v-model="selectCountry"
            label="Страна"
            :items="countries"
            v-model:search="country"
            @update:search="debouncedCountry"
            @update:modelValue="onSubmit"
            item-title="name"
            item-value="id"
            clearable
            density="compact"
            hide-no-data
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="3">
        <v-autocomplete
            label="Жанр"
            v-model="genre"
            :items="genres"
            item-title="name"
            item-value="id"
            clearable
            density="compact"
            @update:modelValue="onSubmit"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="2">
        <v-select
            label="Тип"
            v-model="type"
            :items="types"
            item-title="title"
            item-value="slug"
            density="compact"
            @update:modelValue="onSubmit"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="4">
        <v-select
            label="Сортировка"
            v-model="order"
            :items="sorting"
            item-title="title"
            item-value="slug"
            density="compact"
            @update:modelValue="onSubmit"
            clearable
        ></v-select>
      </v-col>
    </v-row>
  </v-col>
  </v-container>
  <v-container v-if="response">
    <film-list :films="films" />
    <div class="mt-5 w-100" v-if="showMoreBtn">
      <v-btn :loading="isLoading" @click="showMore" color="purple-darken-3" prepend-icon="mdi-plus-circle">Загрузить еще</v-btn>
    </div>
  </v-container>
</template>

<script>
import debounce from "lodash.debounce";
import Api from '@/api/film'
import FilmList from "@/components/FilmList.vue";

export default {
  name: "AdvancedSearch",
  components: {FilmList},
  data() {
    return {
      response: null,
      page: 1,
      films: [],
      dialog: true,
      selectCountry: null,
      yearRange: '2020,2024',
      rating: 6,
      keyword: null,
      type: 'ALL',
      order: 'NUM_VOTE',
      country: null,
      genre: null,
      countries: [],
      genres: [],
      sorting: [
        {
          slug: 'NUM_VOTE',
          title: 'Рейтинг DLE'
        },
        {
          slug: 'RATING',
          title: 'По рейтингу KP'
        }
      ],
      types: [
        {
          slug: 'ALL',
          title: 'Все'
        },
        {
          slug: 'FILM',
          title: 'Фильм'
        },
        {
          slug: 'TV_SERIES',
          title: 'Сериал'
        },
      ],
      years: [
        {
          range: '2020,2024',
          title: '2020 - 2024'
        },
        {
          range: '2010,2020',
          title: '2010 - 2020'
        },
        {
          range: '2000,2010',
          title: '2000 - 2010'
        },
        {
          range: '1990,2000',
          title: '1990 - 2000'
        },
        {
          range: '1950,1990',
          title: '1950 - 1990'
        },
        {
          range: '1890,1950',
          title: 'до 1950'
        },
        {
          range: '1890,' + new Date().getFullYear(),
          title: 'За все время'
        },
      ],
      satisfactionEmojis: ['💩', '🤮', '🤢', '🤕', '😕', '😐', '🙂', '😄', '😍', '🤩'],
    }
  },
  computed: {
    isLoading() {
      return Boolean(this.$store.state.progressBar.loading)
    },
    ratingColor() {
      if (this.rating >= 8) return 'orange-accent-2'
      if (this.rating > 6) return 'green'
      if (this.rating > 4) return 'grey'
      return 'red'
    },
    showMoreBtn() {
      return this.page < this.response.totalPages && this.response.items.length > 0
    }
  },
  methods: {
    showMore() {
      this.page++
      if (this.response.totalPages >= this.page) {
        this.onSubmit('concat')
      }
    },
    debouncedRating: debounce(function () {
      this.onSubmit()
    }, 1000),
    debouncedSearch: debounce(function () {
      if (this.keyword && this.keyword.length >= 3) {
        this.onSubmit()
      }
    }, 500),
    debouncedCountry: debounce(function () {
      if (this.country && this.country.length >= 3) {
        this.getCountries()
      }
    }, 1000),
    async onSubmit(mode) {
      this.$store.dispatch('startProgressBar')
      const filter = {
        countryId: this.selectCountry,
        genreId: this.genre,
        order: this.order,
        type: this.type,
        ratingFrom: this.rating,
        ratingTo: 10,
        yearFrom: this.yearRange ? this.yearRange.split(',')[0] : null,
        yearTo: this.yearRange ? this.yearRange.split(',')[1] : null,
        keyword: this.keyword,
        page: this.page
      }
      await Api.searchByFilter(filter)
          .then(({data}) => {
            this.response = data
            let newData = data.items
            mode === 'concat'
              ? this.films = this.films.concat(newData)
              : this.films = newData
          })
          .then(() => this.$store.dispatch('stopProgressBar'))
    },
    async getCountries() {
      await Api.getCountries().then(data => this.countries = data.data)
    },
    async getGenres() {
      await Api.getGenres().then(data => this.genres = data.data)
    }
  },
  mounted() {
    this.getGenres().then(() => this.onSubmit())
  }
}
</script>

<style scoped>

</style>
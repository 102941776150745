<template>
  <v-card-subtitle>
    <v-icon class="mr-1" icon="mdi-check-circle" color="success" size="small"/>Пароль успешно изменен!
  </v-card-subtitle>
  <v-card-item>
    <div class="d-flex align-center mb-5">
      <div>
        Скопируйте или запишите ваши данные
        <div class="d-flex flex-column mt-5">
          <span class="text-h6">{{ email }}</span>
          <span class="text-h6">{{ password }}</span>
        </div>
      </div>
    </div>
  </v-card-item>
  <v-card-actions class="mx-4 mb-4">
    <v-btn @click="submit" variant="tonal" block class="bg-success mb-2">Закрыть</v-btn>
  </v-card-actions>
</template>

<script>
export default {
  emits: ['submit'],
  name: "SuccessPage",
  props: {
    email: {
      type: String,
      required: true
    },
    password: {
      type: String,
      required: true
    }
  },
  methods: {
    submit() {
      this.$emit('submit', {page: 'success'})
    }
  }
}
</script>

<style scoped>

</style>
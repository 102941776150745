<template>
  <v-hover v-slot="{ isHovering, props }" v-model="hover">
    <v-card
        v-bind="props"
        class="bg-transparent mx-auto"
        max-width="165"
        hover
    >
      <div>
        <v-img :src="newData.posterUrl" :lazy-src="newData.posterUrlPreview" class="rounded-lg bg-grey-darken-4" cover
               :aspect-ratio="3/4">
          <template v-slot:placeholder>
            <div class="d-flex align-center justify-center fill-height">
              <v-progress-circular
                  color="grey-lighten-4"
                  indeterminate
              ></v-progress-circular>
            </div>
          </template>
          <v-expand-transition>
            <div
                v-if="isHovering"
                class="d-flex transition-in-out bg-black"
                style="height: 100%; filter: blur(); opacity: 0.9"
                @click="getPage(newData.kinopoiskId)"
            >
              <div class="pa-2 text-caption  d-flex flex-column justify-space-between w-100">
                <span class="text-subtitle-2">
                  <ShortText :text="newData.nameRu ?? newData.nameEn" :length="30"/>
                </span>
                <ShortText :text="newData.description" :length="65"/>
                <div class="mb-2">
                  <RatingStar :rating="newData.rating"/>
                  <span class="font-weight-bold">{{ newData.year }}</span>
                </div>
              </div>
              <div class="d-flex flex-column align-center justify-space-between bg-blue-darken-4">
                <div class="d-flex flex-column mt-2">
                  <v-btn v-if="isAdmin && pageName === 'home'" icon variant="text" size="small" density="compact"
                         class="mb-2"
                         @click.stop="$emit('remove', film.kinopoiskId)">
                    <v-icon icon="mdi-close"></v-icon>
                  </v-btn>
                  <!--                  <template v-if="pageName !== 'favoritePage'">-->
                  <v-btn icon variant="text" size="small" density="compact" class="mb-2" @click.stop="favChange"
                         text="text">
                    <v-icon :icon="favIcon"></v-icon>
                  </v-btn>
                  <v-btn icon variant="text" size="small" density="compact" class="mb-2" @click.stop="toWatch">
                    <v-icon :icon="watchIcon"></v-icon>
                  </v-btn>
                  <!--                  </template>-->
                </div>
                <span v-if="pageName === 'watchLater'" class="d-inline-block text-caption"
                      style="transform: rotate(270deg); margin: 0 -10px 30px -10px; padding: 0">
             {{ createdAt }}
            </span>
                <span class="text-orange d-inline-block"
                      style="transform: rotate(270deg); margin: 0 -10px 30px -10px; padding: 0"
                >{{ newData.type === "FILM" ? "фильм" : "сериал" }}</span>
              </div>
            </div>
          </v-expand-transition>
        </v-img>
      </div>
      <div class="mt-2 d-flex flex-column">
        <v-btn color="success" block prepend-icon="mdi-movie-open-outline" v-if="isHovering" variant="text"
               density="compact"
               @click="getPage(newData.kinopoiskId)">подробнее
        </v-btn>
        <span v-else class="text-subtitle-1 text-short text-center">
          {{ newData.nameRu ?? newData.nameEn }}
        </span>
      </div>
    </v-card>
  </v-hover>
  <dialog-modal :show="alert" @close="alert = false">
    <template #title>
    </template>
    <template #subtitle>
      Выполните вход или создайте аккаунт
    </template>
    <div>
      Для данного действия необходимо войти в систему
    </div>
  </dialog-modal>
</template>

<script>
import RatingStar from "@/components/UI/RatingStar.vue";
import ShortText from "@/components/UI/ShortText.vue";
import DialogModal from "@/components/UI/DialogModal.vue";

export default {
  emits: ['remove', 'add-fav', 'remove-fav', 'add-to-watch', 'remove-from-watch'],
  name: "FilmCard",
  components: {DialogModal, RatingStar, ShortText},
  props: {
    film: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      alert: false,
      newData: this.film,
      favIcon: '',
      watchIcon: '',
      hover: false
    }
  },
  computed: {
    isLogged() {
      return this.$store.state.auth.isLogged
    },
    isAdmin() {
      return this.$store.state.auth.isAdmin
    },
    pageName() {
      return this.$route.name
    },
    createdAt() {
      const d = new Date(this.film.createdAt)
      return d.getFullYear() + '/' + d.getDate()
    }
  },
  methods: {
    getPage(id) {
      const routeData = this.$router.resolve(
          {
            name: 'detailFilmPage',
            params: {filmId: String(id)}
          });
      window.open(routeData.href, '_blank');
      // return this.$router.push({
      //   name: 'detailFilmPage',
      //   params: {filmId: String(id)},
      //   target: '_blank'
      // })
    },
    favChange() {
      if (this.isLogged) {
        if (this.film.isFav) {
          this.$emit('remove-fav', this.film.kinopoiskId)
          this.favIcon = 'mdi-bookmark-outline'
          this.newData.isFav = false
        } else {
          this.$emit('add-fav', this.film.kinopoiskId)
          this.favIcon = 'mdi-bookmark'
          this.newData.isFav = true
        }
      } else {
        this.alert = true
      }
    },
    toWatch() {
      if (this.isLogged) {
        if (this.film.toWatch) {
          this.$emit('remove-from-watch', this.film.kinopoiskId)
          this.watchIcon = 'mdi-clock-outline'
          this.newData.toWatch = false
        } else {
          this.$emit('add-to-watch', this.film.kinopoiskId)
          this.watchIcon = 'mdi-clock'
          this.newData.toWatch = true
        }
      } else {
        this.alert = true
      }
    }
  },

  mounted() {
    this.favIcon = this.film.isFav ? 'mdi-bookmark' : 'mdi-bookmark-outline'
    this.watchIcon = this.film.toWatch ? 'mdi-clock' : 'mdi-clock-outline'
  }
}
</script>
<style scoped>

</style>
<template>
    <v-list lines="one" class="pa-0">
        <v-list-item>
            <v-chip label :color="filmEpisodes.filmInfo.completed ? 'red' : 'success'">
                {{ filmEpisodes.filmInfo.completed ? 'Сериал завершен' : 'Ждем новых серий' }}
                <span class="text-white ml-3" v-if="!filmEpisodes.filmInfo.completed">{{ filmEpisodes.filmInfo.date }}</span></v-chip>
        </v-list-item>
        <div class="overflow-auto" style="height: 200px">
            <v-list-item
                    v-for="episode in filmEpisodes.episodes"
                    :key="episode">
                <div class="d-flex justify-space-between">
                    <div>
                        <p>{{ episode.name }} эпизод</p>
                    </div>
                    <div>
                        <p class="text-orange">{{ episode.date }}</p>
                    </div>
                </div>
                <v-divider></v-divider>
            </v-list-item>
        </div>
    </v-list>
</template>

<script>
export default {
    name: "LastEpisodes",
    props: {
        filmEpisodes: {
            type: Object,
            required: true
        }
    }
}
</script>

<style scoped>

</style>
import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from "@/store";
import vuetify from './plugins/vuetify'
import breadcrumbs from "vue-3-breadcrumbs";
import {loadFonts} from './plugins/webfontloader';

loadFonts();

const app = createApp(App)
    .use(router)
    .use(store)
    .use(vuetify)
    .use(breadcrumbs, {
        includeComponent: false
    })

app.mount('#app');

import { createStore } from "vuex";
import favorite from "@/store/modules/favorite";
import film from "@/store/modules/film";
import auth from "@/store/modules/auth";
import compilation from "@/store/modules/compilation";
import watchLater from "@/store/modules/watch-later";
import progressBar from "@/store/modules/progress-bar";

const store = createStore({
  modules: {
    auth,
    film,
    favorite,
    compilation,
    watchLater,
    progressBar
  },
});

export default store;

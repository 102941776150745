<template>
  <v-container>
    <div class="d-flex">
      <v-chip label class="mr-3 mb-3" :to="{name: 'favoritePage'}" prepend-icon="mdi-bookmark" color="grey">
        Мои фильмы
      </v-chip>
      <v-chip label :to="{name: 'watchLater'}" prepend-icon="mdi-clock">
        Буду смотреть
      </v-chip>
    </div>
    <v-divider class="mb-5"></v-divider>
    <film-list v-if="films && films.length" :films="films" @remove-from-watch="removeFromWatch"
               @removeFav="removeFav" @add-fav="addFav"/>
    <span v-if="films && films.length === 0">
      Не найдено
    </span>
  </v-container>
</template>

<script>
import FilmList from "@/components/FilmList.vue";

export default {
  name: "WatchLater",
  components: {FilmList},
  data() {
    return {
      films: null
    }
  },
  computed: {
    data() {
      return this.$store.state.watchLater.data
    }
  },
  methods: {
    async getToWatch() {
      await this.$store.dispatch('getListToWatch').then(() => this.films = this.data.films).then(() => this.$store.dispatch('stopProgressBar'))
    },
    async removeFromWatch(id) {
      await this.$store.dispatch('removeToWatchLater', {filmId: id}).then(() => this.getToWatch())
    },
    async addFav(id) {
      await this.$store.dispatch('setFavorite', {filmId: id})
    },
    async removeFav(id) {
      await this.$store.dispatch('removeFavorite', {filmId: id})
    }

  },
  mounted() {
    this.$store.dispatch('startProgressBar');
    this.$store.dispatch('getUser')
    this.getToWatch()
  }
}
</script>
<style scoped>

</style>
<template>
  <form>
    <v-text-field
      required
      class="mt-2"
      variant="outlined"
      v-model="username"
      :error-messages="v$.username.$errors.map((e) => e.$message)"
      :counter="30"
      label="Ваше имя"
      hint="Например: Тонни Старк, Чудо Женщина :)"
      clearable
    ></v-text-field>
    <v-text-field
      required
      class="mt-2"
      variant="outlined"
      v-model="email"
      :error-messages="errors"
      :counter="30"
      label="Ваше e-mail"
      hint="Например: example@mail.com :)"
      clearable
      @keydown.enter="submitForm"
    ></v-text-field>
    <v-row>
      <v-col>
        <v-text-field
          type="password"
          required
          class="mt-2"
          variant="outlined"
          v-model="pass"
          :error-messages="v$.pass.$errors.map((e) => e.$message)"
          :counter="30"
          label="Введите пароль"
          hint="Например: gdahjg^jal%123"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          type="password"
          required
          class="mt-2"
          variant="outlined"
          v-model="confirmPass"
          :error-messages="v$.confirmPass.$errors.map((e) => e.$message)"
          :counter="30"
          label="Подтвердите"
          @keydown.enter="submitForm"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-btn color="success" @click="submitForm" block variant="tonal" :loading="false">
      Регистрация
    </v-btn>
  </form>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required, email, helpers, sameAs, minLength } from "@vuelidate/validators";
import { mapState } from "vuex";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: "RegisterForm",
  emits: ["register"],
  data() {
    return {
      username: "",
      email: "",
      pass: "",
      confirmPass: "",
    };
  },
  computed: {
    ...mapState({
      error: (state) => state.auth.registerError,
    }),
    errors() {
      if (this.error) {
        return "Пользователь с таким e-mail уже существует";
      }
      return this.v$.email.$errors.map((e) => e.$message);
    },
  },
  validations() {
    return {
      username: {
        required: helpers.withMessage("Обязательное поле", required),
      },
      email: {
        required: helpers.withMessage("Обязательное поле", required),
        email: helpers.withMessage("Введен некорректный email", email),
      },
      pass: {
        required: helpers.withMessage("Обязательное поле", required),
        minLength: helpers.withMessage("Не менее 8 сиволов", minLength(8))
      },
      confirmPass: {
        required: helpers.withMessage("Обязательное поле", required),
        sameAs: helpers.withMessage("Пароли не совпадают", sameAs(this.pass)),
      },
    };
  },

  methods: {
    async submitForm() {
      const isFormCorrect = await this.v$.$validate();
      if (isFormCorrect) {
        this.$emit("register", {
          username: this.username,
          email: this.email,
          password: this.pass,
        });
      }
    },
  },
};
</script>

<style scoped></style>

<template>
    <v-row class="filter">
      <v-col cols="3" class="name mb-4 mb-sm-0">
        <v-text-field
            prepend-icon="mdi-filter"
            @input="setFilter"
            v-model="filter.name"
            variant="outlined"
            density="compact"
            placeholder="Поиск"
            label="Фильтр по названию"
            hide-details
        ></v-text-field>
      </v-col>
      <v-col class="genre mb-2 mb-sm-0">
        <v-select
            v-model="filter.genre"
            @update:modelValue="setFilter"
            :items="filterData.genres"
            variant="solo"
            density="compact"
            label="По жанру"
            chips
            multiple
            closable-chips
            hide-details
        ></v-select>
      </v-col>
      <v-col class="country mb-2 mb-sm-0">
        <v-select
            v-model="filter.country"
            @update:modelValue="setFilter"
            :items="filterData.countries"
            variant="solo"
            density="compact"
            chips
            multiple
            persistent-hint
            label="По странам"
            closable-chips
            hide-details
        ></v-select>
      </v-col>
      <v-col class="year mb-2 mb-sm-0">
        <v-select
            v-model="filter.year"
            :items="filterData.years"
            @update:modelValue="setFilter"
            variant="solo"
            density="compact"
            label="По году"
            closable-chips
            chips
            multiple
            hide-details
        ></v-select>
      </v-col>
      <v-col cols="3" class="sort mb-2 mb-sm-0">
        <v-select
            v-model="filter.sort"
            :items="sortName"
            @update:modelValue="setFilter"
            label="Сортировка"
            density="compact"
            variant="solo"
            prepend-icon="mdi-sort-variant"
            clearable
            hide-details
        ></v-select>
      </v-col>
    </v-row>
</template>

<script>
export default {
  emits: ["set-filter"],
  name: "TheFilter",
  props: {
    films: {
      type: Array,
      required: true,
    },
    filterData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      sortName: ["По алфавиту", "По рейтингу", "По дате"],
      filter: {
        sort: "",
        name: "",
        genre: [],
        country: [],
        year: [],
      },
    };
  },
  computed: {
    filtered() {
      let films = this.films;
      if (this.filter) {
        if (this.filter.name) {
          films = films.filter((film) => {
            if (this.filter.name.trim) {
              if (film.nameRu) {
                return film.nameRu
                    .toLowerCase()
                    .includes(this.filter.name.toLowerCase());
              }
              return film.nameOriginal
                  .toLowerCase()
                  .includes(this.filter.name.toLowerCase());
            }
          });
        }
        if (this.filter.country.length > 0) {
          films = films.filter((film) => {
            return film.countries.split(',')
                .find((country) => this.filter.country.includes(country));
          });
        }
        if (this.filter.genre.length > 0) {
          films = films.filter((film) => {
            return film.genres.split(',')
                .find((genre) => this.filter.genre.includes(genre));
          });
        }
        if (this.filter.year.length > 0) {
          films = films.filter((film) => {
            return this.filter.year.includes(film.year);
          });
        }
        if (this.filter.sort) {
          if (this.filter.sort === "По алфавиту") {
            let filmsRu = films.filter((film) => film.nameRu);
            let filmsEn = films.filter((film) => !film.nameRu);
            filmsRu = filmsRu.sort((a, b) =>
                a.nameRu.localeCompare(b.nameRu));
            filmsEn = filmsEn.sort((a, b) =>
                a.nameOriginal.localeCompare(b.nameOriginal)
            );
            films = filmsRu.concat(filmsEn);
          }
          if (this.filter.sort === "По рейтингу") {
            let notNull = films.filter((film) => film.rating);
            let noRating = films.filter((film) => !film.rating);
            notNull = notNull.sort((a, b) => b.rating - a.rating);
            films = notNull.concat(noRating);
          }
          if (this.filter.sort === "По дате") {
            let notNull = films.filter((film) => film.year);
            let noRating = films.filter((film) => !film.year);
            notNull = notNull.sort((a, b) => b.year - a.year);
            films = notNull.concat(noRating);
          }
        }
      }
      return films;
    },
  },
  methods: {
    setFilter() {
      this.$emit("set-filter", this.filtered);
    },
  }
};
</script>

<style scoped>

.name {
  order: 1;
}

.genre {
  order: 2;
}

.country {
  order: 3;
}

.year {
  order: 4;
}

.sort {
  order: 5;
}

@media screen and (max-width: 900px) {
  .filter {
    margin: 20px auto;
    width: 80%;
    flex-direction: column;
  }

  .v-col,
  .v-col-3 {
    max-width: 100%;
    padding: 0;
  }

  .sort {
    order: 0;
  }
}
</style>
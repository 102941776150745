<script>
import {defineComponent} from 'vue'
import axios from "axios";

export default defineComponent({
  name: "TestPage",
  data() {
    return {
      data: null,
      kp: 666
    }
  },
  methods: {
    async getData() {
      await axios.get('https://bazon.cc/api/search?token=843a6ffc0b82a83bfe1c66a62235f5ed&kp=' + this.kp).then(({data}) => this.data = data.results[0])
    }
  }
})
</script>

<template>
  <v-text-field v-model="kp" />
  <v-btn @click="getData"></v-btn>
<!--  <iframe :src="data.link" v-if="data"/>-->
  <v-divider />
<!--  <iframe :src="`https://kinotochka.co/embed/kinopoisk/${kp}`" v-if="kp"/>-->
    <iframe :src="`https://v1693382249.bazon.site/kp/${kp}?trans=1,2,3,4,5,6&poster=https://i.pinimg.com/originals/8a/a7/83/8aa7831e22f8d5c74aecfe0c0e6953e3.jpg`" width="720" height="450" frameborder="0" scrolling="no" allowfullscreen ></iframe>

</template>

<style scoped>

</style>
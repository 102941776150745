<template>
  <v-rating
      readonly
      :model-value="rating"
      :color="colors[Math.floor(rating)]"
      size="small"
      length="10"
      density="compact"
  ></v-rating>
  <v-chip
      class="ma-2"
      :color="colors[Math.floor(rating)]"
      label
      text-color="white"
      prepend-icon="mdi-movie-open-star-outline"
  >{{ rating }}
  </v-chip>
</template>

<script>
export default {
  name: "RatingWidgets",
  props: {
    rating: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      colors: [
        "#000000",
        "#ff0000",
        "#ff0000",
        "#ff0000",
        "#ff0000",
        "#777777",
        "#777777",
        "#3bb33b",
        "#3bb33b",
        "#ffba00",
        "#ffba00",
      ],
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <v-container class="d-flex flex-column justify-center h-100">
    <v-card class="d-flex flex-column align-center pa-16" variant="flat">
      <v-card-title>
      <span class="text-h1">404</span>
      </v-card-title>
      <v-card-text>
        К сожалению конент не найден или удален :(
      </v-card-text>
      <v-card-actions>
        <v-btn :to="{name: 'home'}">
          Вернуться на главную
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "NotFoundPage"
}
</script>
